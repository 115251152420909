import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EnvironmentVariablesService } from '@kenv';
import { Product } from '@ktypes/enums';
import { Theme } from '@ktypes/models';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrowserStorage } from './browser-storage.service';

export interface ThemeStore {
  theme?: Theme;
  availableThemes: Theme[];
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _themeWrapper = document.querySelector('body');
  private _defaultPurposefulTheme = new Theme().deserialize({
    id: '384f4620-3b85-4a1f-b925-6f421cdc636e',
    contentInternalName: 'Default Purposeful Theme',
    displayName: 'Sunset',
    products: ['purposeful'],
    primaryColor: '#8e2057',
    secondaryColor: '#ab392e',
    gradientColor1: '#c85204',
    gradientColor2: '#ab392e',
    gradientColor3: '#8e2057',
    onBackgroundColor: '#ffffff',
    onBackgroundIconAsset: {
      id: '90e43759-16b9-42ad-b380-c4c0c87bd0a3',
      link: '//images.ctfassets.net/q50bah7bgoen/2bzCM4jelNwORCSioI6OYh/31d7f6f0435401856d28bce4b1189b43/purposeful-white.svg',
      type: 'image/svg+xml',
    },
    iconAsset: {
      id: '0c02ded3-3104-4a2a-9f84-76fed889f258',
      link: '//images.ctfassets.net/q50bah7bgoen/5kW21VBNUOUtSL5ur8TrVe/83859a8aa064178e794b578d3fd74a90/purposeful.svg',
      type: 'image/svg+xml',
    },
    backgroundImageAsset: {
      id: '3d1cdd82-7506-489d-8e5a-2f397bd7ca75',
      link: '//images.ctfassets.net/q50bah7bgoen/3eHgjYprFDDhKe35zwPvz1/73b9e444b430e9f45584805e199892ff/background-illustration-nightSky.png',
      type: 'image/png',
    },
  });
  private _defaultInsightfulTheme = new Theme().deserialize({
    id: '9033e9f6-2194-41d8-9765-94d5ada5d8e7',
    contentInternalName: 'Default Insightful Theme',
    displayName: 'Midnight',
    products: ['purposeful'],
    primaryColor: '#202175',
    secondaryColor: '#793682',
    gradientColor1: '#793682',
    gradientColor2: '#202175',
    gradientColor3: '#202175',
    onBackgroundColor: '#ffffff',
    onBackgroundIconAsset: {
      id: '90e43759-16b9-42ad-b380-c4c0c87bd0a3',
      link: '//images.ctfassets.net/q50bah7bgoen/2bzCM4jelNwORCSioI6OYh/31d7f6f0435401856d28bce4b1189b43/purposeful-white.svg',
      type: 'image/svg+xml',
    },
    iconAsset: {
      id: '0c02ded3-3104-4a2a-9f84-76fed889f258',
      link: '//images.ctfassets.net/q50bah7bgoen/5kW21VBNUOUtSL5ur8TrVe/83859a8aa064178e794b578d3fd74a90/purposeful.svg',
      type: 'image/svg+xml',
    },
    backgroundImageAsset: {
      id: '3d1cdd82-7506-489d-8e5a-2f397bd7ca75',
      link: '//images.ctfassets.net/q50bah7bgoen/3eHgjYprFDDhKe35zwPvz1/73b9e444b430e9f45584805e199892ff/background-illustration-nightSky.png',
      type: 'image/png',
    },
  });

  constructor(
    private _browserStorage: BrowserStorage,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    const defaultTheme =
      this._environmentVariablesService.product === Product.purposeful
        ? this._defaultPurposefulTheme
        : this._environmentVariablesService.product === Product.insightful
          ? this._defaultInsightfulTheme
          : null;

    this._themeStore = {
      theme: null, // don't initialize with a theme, the default will be set via changeTheme
      availableThemes: [defaultTheme],
    };
    this._themeStore$.next(this._themeStore);
    this.changeTheme();
  }

  private _themeStore: ThemeStore = { availableThemes: [] };
  private _themeStore$ = new BehaviorSubject<ThemeStore>(null);

  get theme$(): Observable<Theme> {
    return this._themeStore$.pipe(map((store) => store?.theme));
  }

  get availableThemes$(): Observable<Theme[]> {
    return this._themeStore$.pipe(map((store) => store.availableThemes));
  }

  // TODO: Transition async uses of Theme Observables to Signal Properties
  themeStore = toSignal(this._themeStore$);
  theme = toSignal(this.theme$);
  availableThemes = toSignal(this.availableThemes$);

  updateAvailableThemes(themes: Theme[]) {
    this._setAvailableThemes(themes);
  }

  changeTheme(_theme?: Theme) {
    let theme =
      this._environmentVariablesService.product === Product.insightful ? this._defaultInsightfulTheme : _theme;
    if (
      this._environmentVariablesService.product !== Product.insightful &&
      (theme == null || theme?.primaryColor == null)
    ) {
      theme = this._defaultPurposefulTheme;
    }

    if (isEqual(theme, this._themeStore.theme)) {
      // if no change, just exit
      return;
    }

    // Store the new theme
    this._setTheme(theme);

    // Set the current theme variables for CSS use
    this._setThemeWrapperProperty('--primary', theme.primaryColor);
    this._setThemeWrapperProperty('--primary05', theme.getColorWithTransparency('primaryColor', 0.05));
    this._setThemeWrapperProperty('--primary10', theme.getColorWithTransparency('primaryColor', 0.1));
    this._setThemeWrapperProperty('--primary30', theme.getColorWithTransparency('primaryColor', 0.3));
    this._setThemeWrapperProperty('--primary50', theme.getColorWithTransparency('primaryColor', 0.5));
    this._setThemeWrapperProperty('--primary70', theme.getColorWithTransparency('primaryColor', 0.7));
    this._setThemeWrapperProperty('--primary90', theme.getColorWithTransparency('primaryColor', 0.9));
    this._setThemeWrapperProperty('--secondary', theme.secondaryColor);
    this._setThemeWrapperProperty('--gradientColor1', theme.gradientColor1);
    this._setThemeWrapperProperty('--gradientColor2', theme.gradientColor2);
    this._setThemeWrapperProperty('--gradientColor2_30', theme.getColorWithTransparency('gradientColor2', 0.3));
    // gradientColor3 is optional, default to gradientColor2 if it doesn't exist
    this._setThemeWrapperProperty('--gradientColor3', theme.gradientColor3 || theme.gradientColor2);
    this._setThemeWrapperProperty('--onBackground', theme.onBackgroundColor);
    this._setThemeWrapperProperty('--onBackground10', theme.getColorWithTransparency('onBackgroundColor', 0.1));
    this._setThemeWrapperProperty('--onBackground20', theme.getColorWithTransparency('onBackgroundColor', 0.2));
    this._setThemeWrapperProperty('--onBackground30', theme.getColorWithTransparency('onBackgroundColor', 0.3));
    this._setThemeWrapperProperty('--onBackground50', theme.getColorWithTransparency('onBackgroundColor', 0.5));
    this._setThemeWrapperProperty('--onBackground67', theme.getColorWithTransparency('onBackgroundColor', 0.67));
    this._setThemeWrapperProperty('--onBackground80', theme.getColorWithTransparency('onBackgroundColor', 0.8));
    this._setThemeWrapperProperty('--onBackground95', theme.getColorWithTransparency('onBackgroundColor', 0.95));
    this._setThemeWrapperProperty('--iconUrl', theme.iconUrl());
    this._setThemeWrapperProperty('--onBackgroundIconUrl', theme.onBackgroundIconUrl());
    this._setThemeWrapperProperty('--backgroundImageUrl', theme.backgroundImageUrl() || 'none');
  }

  changeThemeById(themeId: string): Theme {
    const theme = this._themeStore.availableThemes.find((theme) => theme.id === themeId);
    if (theme) {
      this.changeTheme(theme);
      return theme;
    }
    return null;
  }

  changeThemeByDisplayName(themeName: string): Theme {
    const theme = this._themeStore.availableThemes.find((theme) => theme.displayName === themeName);
    if (theme) {
      this.changeTheme(theme);
      return theme;
    }
    return null;
  }

  revertTheme(theme: Theme) {
    this.changeTheme(
      theme ?? this._environmentVariablesService.product === Product.insightful
        ? this._defaultInsightfulTheme
        : this._defaultPurposefulTheme
    );
  }

  removeTheme() {
    this._themeStore = merge({}, this._themeStore);
    if (this._themeStore.theme) {
      delete this._themeStore.theme;
      this._browserStorage.remove('theme');
    }
  }

  private _setTheme(theme: Theme, updateStream = true) {
    // NOTE: use `changeTheme` to update the theme, rather than calling this directly
    if (!theme || isEqual(theme, this._themeStore.theme)) {
      return;
    }
    this._themeStore = {
      ...this._themeStore,
      theme,
    };
    if (updateStream) {
      this._themeStore$.next(this._themeStore);
    }
  }

  private _setAvailableThemes(availableThemes: Theme[], updateStream = true) {
    // NOTE: use `updateAvailableThemes` to update the theme, rather than calling this directly
    if (!availableThemes || isEqual(availableThemes, this._themeStore.availableThemes)) {
      return;
    }
    this._themeStore = {
      ...this._themeStore,
      availableThemes,
    };
    if (updateStream) {
      this._themeStore$.next(this._themeStore);
    }
  }

  private _setThemeWrapperProperty(propertyName: string, value: string) {
    if (value != null) {
      this._themeWrapper.style.setProperty(propertyName, value);
    } else {
      this._themeWrapper.style.removeProperty(propertyName);
    }
  }
}
