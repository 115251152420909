import { computed, Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { setExpiringSignal } from '../utils/signal.util';

interface LastBrowserEvent {
  position: number;
  direction: -1 | 0 | 1;
  event: PageTransitionEvent | PopStateEvent;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private _router: Router) {
    window.addEventListener('pageshow', this._trackBrowserHistoryEvents.bind(this));
    window.addEventListener('popstate', this._trackBrowserHistoryEvents.bind(this));

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history().at(-1) === event.urlAfterRedirects) {
          // do nothing if landing on the same page noted as last in history
          return;
        }
        this._history.set([...this._history(), event.urlAfterRedirects]);

        // comment in for basic page history debugging
        // console.log('history: ', this._history());
        // console.log('lastBrowserEvent', this.lastBrowserEvent());
        // console.log('positionLastShown', window.localStorage?.getItem?.('positionLastShown'));
      }
    });
  }

  // private _browserEventSetPosition = false;
  private _history = signal<string[]>([]);
  private _lastBrowserEvent = signal<LastBrowserEvent>(null);

  history = computed(() => this._history());
  lastBrowserEvent = computed(() => this._lastBrowserEvent());
  latest = computed(() => this._history().at(-1));

  back(): void {
    const prevPage = this._history().length > 1 && this._history().at(-2);
    if (prevPage) {
      this._history.set(this._history().slice(0, -1)); // remove the current page
      void this._router.navigateByUrl(prevPage);
    } else {
      this.clear(); // clear history
      void this._router.navigate(['']);
    }
  }

  clear() {
    this._history.set([]);
  }

  private _trackBrowserHistoryEvents(event: PageTransitionEvent | PopStateEvent) {
    // Browser event rather than Angular navigation event
    setExpiringSignal<LastBrowserEvent>(this._lastBrowserEvent, { position: null, direction: null, event }, 400);
  }
}

/*
// This function seems to work in simple pages but because Angular changes the browser history during navigation,
// it is not accurate for direction in this app.
// FROM: http://reluk.ca/project/Web/test/travel_direction/local_storage/
// SO Link: https://stackoverflow.com/a/49329267/228429
// TODO: Adapt to work with Angular and use in _trackBrowserHistoryEvent method in order to capture direction

function reorient() {
  // After travelling in the history stack

  // If none, then zero
  const positionLastShown = Number(localStorage.getItem('positionLastShown'));
  // Absolute position in stack (? this is an object, not a number, does it mean state.navigationId?)
  let position = history.state;
  if (position === null) {
    // Meaning a new entry on the stack
    position = positionLastShown + 1; // Top of stack

    // Stamp the entry with its own position in the stack
    // - Could be problematic in Angular if it is already updating the history entry on navigation
    // history.replaceState(position, ''); // no title, 2nd attribute
  }

  // Keep track of the last position shown
  localStorage.setItem('positionLastShown', String(position));

  // Discover the direction of travel by comparing the two
  // One of backward (-1), reload (0) and forward (1)
  const direction = Math.sign(position - positionLastShown);
  console.log('Travel direction is ' + direction);
  console.log('   Present position: ' + position);
  console.log('   Travel direction: ' + direction);
}

addEventListener('pageshow', reorient); // Generally only from page refresh?
addEventListener('popstate', reorient); // Travel in same page
*/
