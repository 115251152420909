import { computed, Injectable, signal } from '@angular/core';
import { OGApi, UserEventApi } from '@kapi';
import { BrowserStorage } from '@kservice';
import { UserEvent } from '@ktypes/enums';
import { Group, InsightfulAdminGroupInfo } from '@ktypes/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingBloc {
  constructor(
    private _browserStorage: BrowserStorage,
    private _ogApi: OGApi,
    private _userEventApi: UserEventApi
  ) {
    const groupInfo = this.readGroupInformation();
    if (groupInfo) {
      this._groupInfo.set(groupInfo);
    }
  }

  group$ = new BehaviorSubject<Group>(null);

  private _groupInfo = signal<InsightfulAdminGroupInfo>(null);
  groupInfo = computed(() => this._groupInfo());

  get isOnboarding(): boolean {
    return this._browserStorage.getObject('onboarding') != null;
  }

  getOnboardingGroup(groupCode: string): void {
    this._ogApi.getGroupByCode(groupCode).then((group) => {
      this.group$.next(group);
    });
  }

  sendOnboardingUserEvent(eventKey: UserEvent): void {
    void this._userEventApi.postUserEvent(eventKey);
  }

  saveGroupInformation(groupInfo: Partial<InsightfulAdminGroupInfo>) {
    const combinedGroupInfo = { ...this.readGroupInformation(), ...groupInfo };
    this._browserStorage.setObject('groupInfo', combinedGroupInfo, 1, true);
    this._groupInfo.set(combinedGroupInfo);
  }

  readGroupInformation(): InsightfulAdminGroupInfo {
    return this._browserStorage.getObject('groupInfo', true) as InsightfulAdminGroupInfo;
  }

  cleanUpOnboarding() {
    this._browserStorage.remove('groupInfo', true);
  }
}
