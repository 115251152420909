import { NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-checkbox',
  standalone: true,
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  imports: [NgClass],
})
export class CheckboxComponent {
  @Input() isChecked: boolean;
  @Input() reversed = false;
  @Input() semanticLabel: string; // Note: Translate before passing in
  @Output() check = new EventEmitter<boolean>();
  @ViewChild('checkboxInput', { static: false }) checkbox: ElementRef;

  checkboxChanged(inputChecked: boolean) {
    this.check.emit(inputChecked);
  }
}

export const MockCheckboxComponent = MockComponent({
  selector: 'kui-checkbox',
  inputs: ['isChecked', 'reversed', 'semanticLabel'],
  template: '<ng-content></ng-content>',
});
