import { AnalyticsPageName, PageMappingData } from '@kanalytics';

export const pageMappingData: PageMappingData = {
  pages: [
    { pageName: AnalyticsPageName.dashboard, regex: /^\/dashboard.*/, meta: { focusElementSelector: '.title' } },
    { pageName: AnalyticsPageName.help, regex: /^\/help.*/ },
    {
      pageName: AnalyticsPageName.verifyMfaResendCode,
      regex: /^\/login\/email\/verify-mfa\/no-code.*/,
      meta: { focusTitle: "Didn't Receive a Code?" },
    },
    {
      pageName: AnalyticsPageName.verifyMfa,
      regex: /^\/login\/email\/verify-mfa.*/,
      meta: { focusTitle: "Verify It's You" },
    },
    { pageName: AnalyticsPageName.login, regex: /^\/login.*/ },
    { pageName: AnalyticsPageName.privacy, regex: /^\/privacy.*/ },
    { pageName: AnalyticsPageName.reports, regex: /^\/reports.*/, meta: { focusElementSelector: '.title' } },
    {
      pageName: AnalyticsPageName.insightfulAdminOnboardingVerifyEmail,
      regex: /^\/onboarding\/verify-email.*/,
      meta: { focusElementSelector: '.header' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminOnboardingVerifyNoCode,
      regex: /^\/onboarding\/verify-email\/no-code.*/,
      meta: { focusElementSelector: '.header' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminOnboardingPlan,
      regex: /^\/onboarding\/plan.*/,
      meta: { focusElementSelector: '.heading' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminOnboardingConfirmation,
      regex: /^\/onboarding\/confirmation.*/,
      meta: { focusElementSelector: '.heading' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminOnboardingSignup,
      regex: /^\/onboarding.*/,
      meta: { focusElementSelector: '.heading' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminSettings,
      regex: /^\/admin\/settings.*/,
      meta: { focusElementSelector: '.heading' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminToolkit,
      regex: /^\/admin\/toolkit.*/,
      meta: { focusElementSelector: '.heading' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminManage,
      regex: /^\/admin\/manage.*/,
      meta: { focusElementSelector: '.heading' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminDelete,
      regex: /^\/admin\/delete.*/,
      meta: { focusElementSelector: '.heading' },
    },
    {
      pageName: AnalyticsPageName.insightfulAdminLanding,
      regex: /^\/admin.*/,
      meta: { focusElementSelector: '.heading' },
    },
  ],
  dontTrack: ['/login/email', '/login/reset/code', '/login/reset/password', '/logout'],
  dontCache: ['/login'],
};
