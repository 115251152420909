import { compareDesc } from 'date-fns/compareDesc';
import { EligibilityVerificationType } from '../enums';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LiveSupport } from './live-support.model';
import { Report } from './report.model';
import { SubscriptionPlan } from './subscription-plan.model';
import { Theme } from './theme.model';
import { Url } from './url.model';

// NOTE: This file contains the classes for Organization and Group to avoid a Circular Dependency Warning
// as the classes both depend on each other

const LICENSE_WARNING_PERCENT = 0.1; // 10%
const WARNING_MONTHS = 3;
const DEFAULT_EXPIRATION = '2999-12-31';

export class Organization implements Deserializable<JsonObject, Organization> {
  distributor: Organization | JsonObject<Organization>;
  constructor(
    public id?: string,
    public name?: string,
    _distributor?: Organization,
    public groups?: Group[],
    public hasAccess?: boolean,
    // Reports data objects
    public reports?: Report[],
    public active?: boolean,
    public expirationDate?: Date
  ) {
    if (_distributor != null) {
      this.distributor = new Organization(_distributor.id, _distributor.name);
    }
    if (expirationDate == null) {
      this.expirationDate = new Date(DEFAULT_EXPIRATION);
    }
  }

  get isTest(): boolean {
    return (
      getTestOrgs().includes(this.name) || this.groups?.every((group) => getTestGroups().includes(group.groupCode))
    );
  }

  get noActiveGroups(): boolean {
    return this.groups?.every((group) => group.expired || !group.active);
  }

  get expired(): boolean {
    return this.expirationDate && compareDesc(this.expirationDate, new Date()) === 1;
  }

  get expiredWarning(): boolean {
    const date = new Date();
    date.setMonth(date.getMonth() + WARNING_MONTHS);
    return (
      !this.expired &&
      ((this.expirationDate && compareDesc(this.expirationDate, date) === 1) ||
        this.groups?.some(
          (group) => !group.expired && group.expirationDate && compareDesc(group.expirationDate, date) === 1
        ))
    );
  }

  deserialize(json: JsonObject, existingData?: Organization): Organization {
    if (json == null) {
      return null;
    }

    this.id = (json['id'] as string) ?? existingData?.id;
    this.name = (json['name'] as string) || existingData?.name;
    const normalizedDistributor = (json['distributor'] as Organization) ?? existingData?.distributor;
    this.distributor = normalizedDistributor && new Organization().deserialize(normalizedDistributor);
    this.groups = ((json['groups'] as Group[]) ?? existingData?.groups ?? []).map((group) =>
      new Group().deserialize(group)
    );
    this.hasAccess = !!json['hasAccess'];

    const reportsJson = (json['reports'] as Report[]) ?? existingData?.reports;
    if (Object.keys(reportsJson || {}).length > 0) {
      this.reports = reportsJson?.map?.((report) => new Report().deserialize(report));
    }
    this.active = !!json['active'] ?? existingData?.active;
    this.expirationDate =
      json['expirationDate'] || existingData?.expirationDate
        ? new Date(json['expirationDate']) ?? existingData?.expirationDate
        : new Date(DEFAULT_EXPIRATION);

    return this;
  }

  addReports(reports: Report[] | JsonObject) {
    if (Array.isArray(reports) && reports.every((report) => report instanceof Report)) {
      this.reports = reports;
    } else {
      this.reports = (reports as Report[])?.map?.((report) => new Report().deserialize(report));
    }
  }
}

export class Group implements Deserializable<JsonObject, Group> {
  constructor(
    public id?: string,
    public name?: string,
    public groupCode?: string,
    public organization?: Organization,
    public organizationName?: string,
    public dateCreated?: Date,
    public eligibilityVerificationTypes?: EligibilityVerificationType[],
    public eligibilityFileHasFamilyMembers?: boolean,
    public liveSupport?: LiveSupport,
    public singleSignOn?: boolean,
    public urls?: Url[],
    public subscriptionPlans?: SubscriptionPlan[],
    public theme?: Theme,
    public active?: boolean,
    public expirationDate?: Date,
    public totalLicenses?: number,
    public usedLicenses?: number
  ) {
    if (expirationDate == null) {
      this.expirationDate = new Date(DEFAULT_EXPIRATION);
    }
  }

  get isTest(): boolean {
    return getTestGroups().includes(this.groupCode);
  }

  get expired(): boolean {
    return this.expirationDate && compareDesc(this.expirationDate, new Date()) === 1;
  }

  get expiredWarning(): boolean {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + WARNING_MONTHS);
    return !this.expired && this.expirationDate && compareDesc(this.expirationDate, currentDate) === 1;
  }

  deserialize(json: JsonObject, existingData?: Group): Group {
    if (json == null) {
      return null;
    }

    this.id = (json['id'] as string) ?? existingData?.id;
    // checking for groupName for groupValidation responses that send name that way
    this.name =
      ((json['name'] || json['groupName']) as string) ??
      ((existingData?.name || (existingData as JsonObject)?.['groupName']) as string);
    this.groupCode = (json['groupCode'] as string) ?? existingData?.groupCode;
    this.organization = new Organization().deserialize(
      {
        ...json['organization'],
        name: (json['organizationName'] as string) ?? ((json['organization'] as JsonObject)?.['name'] as string),
      } ?? existingData?.organization
    );
    this.organizationName = (json['organizationName'] as string) ?? existingData?.organizationName;
    if (json['dateCreated']) {
      this.dateCreated = new Date(json['dateCreated']) ?? existingData?.dateCreated;
    }
    this.eligibilityVerificationTypes = (json['eligibilityVerificationTypes'] ??
      existingData?.eligibilityVerificationTypes) as EligibilityVerificationType[];
    this.eligibilityFileHasFamilyMembers =
      !!json['eligibilityFileHasFamilyMembers'] ?? existingData?.eligibilityFileHasFamilyMembers;
    this.liveSupport = new LiveSupport().deserialize(json['liveSupport'] ?? existingData?.liveSupport);
    this.singleSignOn = !!json['singleSignOn'] ?? existingData?.singleSignOn;
    this.urls = ((json['urls'] as Url[]) ?? existingData?.urls)?.map((url) => new Url().deserialize(url));
    this.subscriptionPlans = (
      (json['subscriptionPlans'] as SubscriptionPlan[]) ?? existingData?.subscriptionPlans
    )?.map((plan) => new SubscriptionPlan().deserialize(plan));
    this.theme = new Theme().deserialize(json['theme'] ?? existingData?.theme);
    this.active = !!json['active'] ?? existingData?.active;
    this.expirationDate =
      json['expirationDate'] || existingData?.expirationDate
        ? new Date(json['expirationDate']) ?? existingData?.expirationDate
        : new Date(DEFAULT_EXPIRATION);
    this.totalLicenses = json['totalLicenses'] as number;
    this.usedLicenses =
      json['usedLicenses'] == null || isNaN(Number(json['usedLicenses'])) ? null : Number(json['usedLicenses']);

    return this;
  }
}

interface ShareSettings {
  spouseEnabled: boolean;
  spouseGroup: Partial<Group>;
  coworkerEnabled: boolean;
  coworkerLimit: number;
  friendEnabled: boolean;
  friendGroup: Partial<Group>;
}

export class GroupForAdmin extends Group implements Deserializable<JsonObject, GroupForAdmin> {
  constructor(
    public id?: string,
    public name?: string,
    public groupCode?: string,
    public organization?: Organization,
    public organizationName?: string,
    public dateCreated?: Date,
    public eligibilityVerificationTypes?: EligibilityVerificationType[],
    public eligibilityFileHasFamilyMembers?: boolean,
    public liveSupport?: LiveSupport,
    public singleSignOn?: boolean,
    public urls?: Url[],
    public subscriptionPlans?: SubscriptionPlan[],
    public theme?: Theme,
    public active?: boolean,
    // Admin specific
    public expirationDate?: Date,
    public gracePeriod?: number,
    public accountDuration?: number,
    public totalLicenses?: number,
    public licensesRemaining?: number,
    public purposeful_landing?: boolean,
    public resourceful_landing?: boolean,
    public shareSettings?: ShareSettings,
    public tags?: string[]
  ) {
    super();
  }

  get licenseWarning(): boolean {
    if (isNaN(this.licensesRemaining) || isNaN(this.totalLicenses)) {
      return false;
    }
    return this.licensesRemaining / this.totalLicenses < LICENSE_WARNING_PERCENT;
  }

  deserialize(json: JsonObject, existingData?: GroupForAdmin): GroupForAdmin {
    if (json == null) {
      return null;
    }

    // run Group deserialize
    Object.assign(this, new Group().deserialize(json, existingData) as GroupForAdmin);

    if (json['expirationDate']) {
      this.expirationDate = new Date(json['expirationDate']) ?? existingData?.expirationDate;
    }
    this.totalLicenses = (json['totalLicenses'] as number) ?? existingData?.totalLicenses;
    this.licensesRemaining = (json['licensesRemaining'] as number) ?? existingData?.licensesRemaining;
    this.gracePeriod = (json['gracePeriod'] as number) ?? existingData?.gracePeriod;
    this.accountDuration = (json['accountDuration'] as number) ?? existingData?.accountDuration;
    this.purposeful_landing = !!json['purposeful_landing'] ?? existingData?.purposeful_landing;
    this.resourceful_landing = !!json['resourceful_landing'] ?? existingData?.resourceful_landing;
    this.shareSettings = (json['shareSettings'] as ShareSettings) ?? existingData?.shareSettings;
    this.tags = (json['tags'] as string[]) ?? existingData?.tags;
    // TODO: Normalize API to send `active` like regular group
    this.active =
      !!(json['isActive'] || json['active']) ?? !!((existingData as JsonObject)?.['isActive'] || existingData?.active);

    return this;
  }
}

// TODO: this isn't comprehensive, eventually we should make a way to mark an org or group as test in Contentful
const TEST_ORG_NAMES: string[] = [
  'Feltz sync test',
  'Kumanu Load Test',
  'Kumanu Test',
  'Org 1',
  'Test Org',
  'Trial',
  'Tiny Test Org',
  'Demo',
  'Client Family Org -test',
  'Client SubA Org - test',
  'Client Main Org - test',
  'Test Distributor',
  'CS Sandbox',
  'Kumanu Client Success',
];
const TEST_GROUP_CODES: string[] = [
  'surgeon',
  'business_solver_demo',
  'demo-punchcut',
  'salesdemo',
  'demo-st',
  'costco-demo',
  'demo-walkthru',
  'demo-uhg',
  'demo-worklife',
  'demo-smb-us',
  'demo-lt',
  'demo-friends',
  'demo-plx',
  'demo-cbp',
  'demo-sb',
  'demo-mmo',
  'demo-aarp',
  'demo-promedica',
  'baystate-demo',
  'highmark-demo',
  'trunorth-demo',
  'demo-family',
  'demo-cdccom',
  'kumanu-demo',
  'demo-wtw',
  'demo24',
  'demo-sesprs',
  'demo-mobe',
  'demo-535',
  'ch-demo',
  'challenges-demo',
  'vip-demo',
  'evolve-demo',
  'highmark',
  'demo-smb-nus',
  'PRD',
  'RD',
  'demo-umccc',
  'demo15',
  'PD',
  'huntington-demo',
  'inspiring_capital',
  'beta-testers',
  'edlogics-demo',
  'umn919',
  'feltz-03x',
  'kumanu-load-test-november2021',
  'a1',
  'abt',
  'flag-test',
  'c1',
  'e2e',
  'ShortDemo',
  'client_test',
  'd1',
  'kumanu_test_spouses',
  'fresh-sandbox',
  'mwbw',
  'b1',
  'lptg',
  'test_friends',
  'sdoh-sandbox',
  'mobe-sandbox',
  'baystate-sandbox',
  'trunorth-sandbox',
  'ptef',
  'sandbox',
  'AAAHC-Test',
  'testkey',
  'feltz01x',
  'CCTest',
  'wct',
  'ptnf',
  'feltz02-x',
  'JTG',
  's1u',
  's1',
  's2',
  'g2',
  'g1',
  'john1',
  'joe1',
  'kumanu2019',
  'org-1-grace',
  'org-no-grace',
  'org-1-dd',
  '1dd',
  'PRT60',
  'RT14',
  'RT30',
  'PRT14',
  'trial120',
  'PT30',
  'PRT30',
  'PT60',
  'RT60',
  'PT14',
  'UHCMedSupp2-sandbox',
  'USBP-family-cs',
  'confluence-cs',
  'baystate-cs',
  'cbp-test',
  'cbp-test-family',
  'feltz-04x',
  'feltz-01x',
  'feltz-02x',
  'state-stree-rfp',
  'kumanu-gpt',
];

function getTestOrgs() {
  return TEST_ORG_NAMES;
}

function getTestGroups() {
  return TEST_GROUP_CODES;
}
