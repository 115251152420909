import { BLOCKS, Document } from '@contentful/rich-text-types';
import { DateTimeUtil } from '@kutil';
import { ContentMedia } from './content-media.model';
import { Deserializable } from './deserializable.model';
import { Domain } from './domain.model';
import { JsonObject } from './json-object.model';

export enum CenterPath {
  ARROW = 'ARROW',
  CHECK = 'CHECK',
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL',
  EMPTY = 'EMPTY',
}

export enum SocialChallengeState {
  upcoming = 'upcoming',
  active = 'active',
  ended = 'ended',
}

export interface SocialChallengeGroupStatus {
  goalCompleted?: boolean; // NOTE: Currently calculated by client
  progress?: { count: number };
  participating?: number;
}

export interface SocialChallengeIndividualStatus {
  completedToday?: boolean;
  enrolled?: boolean;
  goalCompleted?: boolean; // NOTE: Currently calculated by client
  joinDate?: Date;
  progress?: { count?: number; countToday?: number };
}

export interface SocialChallengeInfo<StatusType> {
  goal: number;
  instruction?: Document;
  motivation: Document;
  completionMessage: Document;
  status: StatusType;
}

export interface SocialChallengeStatus {
  state: SocialChallengeState;
  daysLeft: number;
}

export class SocialChallengeFitnessTarget implements Deserializable<JsonObject, SocialChallengeFitnessTarget> {
  goal?: number;
  targetType?: string;
  units?: string;
  description?: string;
  progress?: number;
  lastSyncDate?: Date;
  completedToday?: boolean;
  imagePath?: string;
  wasLastSyncedToday?: boolean;

  deserialize(input: JsonObject): SocialChallengeFitnessTarget {
    if (input == null) {
      return null;
    }

    this.goal = input['targetGoal'] as number;
    this.targetType = input['targetType'] as string;
    this.units = input['targetUnits'] as string;
    this.description = input['description'] as string;
    this.progress = (input['progress'] as number) ?? 0;
    this.lastSyncDate = input['lastSyncDate'] as Date;
    this.completedToday = (input['completedToday'] as boolean) ?? false;

    this.imagePath = this._imagePath();
    this.wasLastSyncedToday = this._wasLastSyncedToday();

    return this;
  }

  private _imagePath() {
    if (this.completedToday) {
      return '/assets/icons/checkmark.svg';
    }

    switch (this.targetType) {
      case 'Steps':
        return '/assets/icons/target-steps.svg';
      case 'Mindful Minutes':
        return '/assets/icons/target-mindfulness.svg';
      case 'Exercise Minutes':
        return '/assets/icons/target-exercise.svg';
      default:
        return '/assets/icons/link.svg';
    }
  }

  private _wasLastSyncedToday() {
    if (!this.lastSyncDate) {
      return false;
    }

    return DateTimeUtil.isSameDay(this.lastSyncDate, new Date());
  }
}

const groupInstruction: Document = getGroupInstruction() as Document;

export class SocialChallenge implements Deserializable<JsonObject, SocialChallenge> {
  constructor(
    public id?: string,
    public contentId?: string,
    public title?: string,
    public domain?: Domain,
    public description?: Document,
    public objective?: string,
    public mediaObject?: ContentMedia,
    public startDate?: Date,
    public endDate?: Date,
    public inviteMessage?: string,
    _instruction?: Document, // NOTE: inserted under individuals
    public group?: SocialChallengeInfo<SocialChallengeGroupStatus>,
    public individual?: SocialChallengeInfo<SocialChallengeIndividualStatus>,
    public status?: SocialChallengeStatus,
    public fitnessTargets?: SocialChallengeFitnessTarget[],
    public hasFitnessTargets?: boolean,
    public hasCompletedActionToday?: boolean,
    public hasDeviceConnected?: boolean
  ) {
    if (_instruction && individual && !individual.instruction) {
      individual.instruction = _instruction;
    }
    if (groupInstruction && group && !group.instruction) {
      group.instruction = groupInstruction;
    }
  }

  // TODO: Remove or refactor if goalCompleted is set by server
  get groupGoalCompleted() {
    return this.group.status.progress?.count >= this.group.goal;
  }

  // TODO: Remove or refactor if goalCompleted is set by server
  get individualGoalCompleted() {
    return this.individual.status.progress?.count >= this.individual.goal;
  }

  get image() {
    return this.mediaObject?.asImage;
  }

  private _hasFitnessTargets() {
    return (this.fitnessTargets?.length ?? 0) > 0;
  }

  private _hasCompletedActionToday() {
    return (this.individual?.status?.progress?.countToday ?? 0) > 0;
  }

  private _hasDeviceConnected() {
    if (!this._hasFitnessTargets()) {
      return false;
    }

    return this.fitnessTargets.some(
      (target) => !!(target.lastSyncDate || target.completedToday || (target.progress ?? 0) > 0)
    );
  }

  deserialize(input: JsonObject): SocialChallenge {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.contentId = input['contentId'] as string;
    this.title = input['title'] as string;
    this.domain = new Domain().deserialize(input['domain']);
    this.description = input['description'] as Document; // RICH_TEXT
    this.objective = input['objective'] as string; // RICH_TEXT
    this.mediaObject = new ContentMedia().deserialize(input['mediaObject']);
    if (input['startDate'] != null) {
      this.startDate = new Date(input['startDate']);
    }
    if (input['endDate'] != null) {
      this.endDate = new Date(input['endDate']);
    }
    this.inviteMessage = input['inviteMessage'] as string;

    // Challenge Group
    this.group = (input['group'] || {}) as SocialChallengeInfo<SocialChallengeGroupStatus>;
    if (this.group.status == null) {
      this.group.status = {};
    }
    if (this.group.status.progress == null) {
      this.group.status.progress = { count: 0 };
    }
    this.group.instruction = groupInstruction; // RICH_TEXT
    // TODO: Remove if goalCompleted is set by server
    if (this.group.status?.goalCompleted == null) {
      // NOTE: this is a static value, so won't update on progress change dynamically
      this.group.status.goalCompleted = this.group.status.progress?.count >= this.group.goal;
    }

    // Challenge Individual
    this.individual = (input['individual'] || {}) as SocialChallengeInfo<SocialChallengeIndividualStatus>;
    if (this.individual.status == null) {
      this.individual.status = {};
    }
    if (this.individual.status.progress == null) {
      // assume if progress isn't sent, it is 0
      this.individual.status.progress = { count: 0, countToday: 0 };
    }
    if (((input['individual'] as JsonObject)?.['status'] as JsonObject)?.['joinDate'] != null) {
      // ensure joinDate is a Date if exists
      this.individual.status.joinDate = new Date(
        ((input['individual'] as JsonObject)['status'] as JsonObject)['joinDate']
      );
    } else {
      // remove if null (sent from server)
      delete this.individual.status.joinDate;
    }
    if (this.individual.instruction == null) {
      this.individual.instruction = input['instruction'] as Document; // RICH_TEXT
    }

    // TODO: Remove if goalCompleted is set by server
    if (this.individual.status?.goalCompleted == null) {
      // NOTE: this is a static value, so won't update on progress change dynamically
      this.individual.status.goalCompleted = this.individual.status.progress?.count >= this.individual.goal;
    }

    // Fix UI so -1/0 can't be displayed for "participating" count due to optimistic updates
    if (this.group.status.participating <= 0 && this.individual.status.enrolled) {
      this.group.status.participating = 1;
    }

    // Challenge Status
    this.status = (input['status'] || {}) as SocialChallengeStatus;

    // Fitness targets
    this.fitnessTargets = (input['fitnessTargets'] as JsonObject[])?.map((target: JsonObject) =>
      new SocialChallengeFitnessTarget().deserialize(target)
    );

    this.hasFitnessTargets = this._hasFitnessTargets();

    this.hasCompletedActionToday = this._hasCompletedActionToday();

    this.hasDeviceConnected = this._hasDeviceConnected();

    return this;
  }
}

export function getGroupInstruction(raw = true): JsonObject | Document {
  return {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [{ type: 'italic' }],
            value: 'Challenges are social events — your participation helps the community reach its goal.',
            nodeType: 'text',
          },
        ],
        nodeType: raw ? 'paragraph' : BLOCKS.PARAGRAPH,
      },
    ],
    nodeType: raw ? 'document' : BLOCKS.DOCUMENT,
  };
}
