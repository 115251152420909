@if ({ info: (globalElementsService.currentPageInfo$ | async) }; as page) {
  <kui-a11y-live-announce
    [announcement]="
      'Navigated to new page, ' + (page.info?.meta?.focusTitle || page.info?.pageName || '')
    "></kui-a11y-live-announce>
}

<span id="browser-nav-focus" class="visually-hidden" tabindex="-1"></span>
<a class="skip-to-content-link" [href]="skipLinkPath">Skip to Main Content</a>
@if ((globalElementsService.showChrome$ | async) && (globalElementsService.showHeader$ | async)) {
  <ki-site-header
    [isOnboarding]="globalElementsService.showOnboarding$ | async"
    [showEligibleDropDown]="globalElementsService.showEligibleDropdown$ | async"
    [showGroupsDropdown]="globalElementsService.showGroupsDropdown$ | async"
    [navActive]="navActive"
    (navBtnClick)="navBtnClicked()"></ki-site-header>
}
<section
  class="main"
  [ngClass]="{
    'no-chrome': (globalElementsService.showChrome$ | async) === false,
    'no-header': (globalElementsService.showHeader$ | async) === false,
    'no-nav': (globalElementsService.showNav$ | async) === false
  }">
  @if ((globalElementsService.showChrome$ | async) && (globalElementsService.showNav$ | async)) {
    <ki-site-nav [navActive]="navActive" (sidebarClick)="sidebarClicked()"></ki-site-nav>
  }
  <main class="content" id="main">
    <span id="nav-focus" class="visually-hidden" tabindex="-1"></span>
    <router-outlet></router-outlet>
  </main>
</section>
<kui-version-tag [hidden]="true"></kui-version-tag>
