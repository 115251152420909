export enum ErrorState {
  allOrganizations,
  denied,
  generic,
  noOrganization,
  noReports,
  notEnoughData,
  notFound,
  initial_payment_needed,
  payment_needed,
  payment_required,
}

// TODO: Use error types for better error validation
export type PasswordErrorState = 'PasswordResetRequiredException' | 'InvalidPasswordException';
export type CodeErrorState = 'CodeMismatchException' | 'ExpiredCodeException' | 'CodeDeliveryFailureException';
export type UserCreationErrorState = 'LimitExceededException' | 'UsernameExistsException' | 'AliasExistsException';
export type AuthErrorState = PasswordErrorState | CodeErrorState | UserCreationErrorState;
export type PaymentErrorState = 'initial_payment_needed' | 'payment_needed' | 'payment_required';
