import { signal } from '@angular/core';
import { hexToRgb } from '@kutil';
import pick from 'lodash/pick';
import { Product } from '../enums';
import { ContentAsset } from './content-asset.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';

type IndexableColors =
  | 'primaryColor'
  | 'secondaryColor'
  | 'gradientColor1'
  | 'gradientColor2'
  | 'gradientColor3'
  | 'onBackgroundColor';

export class Theme implements Deserializable<JsonObject, Theme>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public displayName?: string,
    public primaryColor?: string,
    public secondaryColor?: string,
    public gradientColor1?: string,
    public gradientColor2?: string,
    public gradientColor3?: string,
    public onBackgroundColor?: string,
    public onBackgroundIconAsset?: ContentAsset,
    public iconAsset?: ContentAsset,
    public backgroundImageAsset?: ContentAsset,
    public products?: Product[]
  ) {}

  onBackgroundIconUrl = signal<string>(this.onBackgroundIconAsset?.link);
  iconUrl = signal<string>(this.iconAsset?.link);
  backgroundImageUrl = signal<string>(this.backgroundImageAsset?.backgroundLink);

  deserialize(input: JsonObject): Theme {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.displayName = input['displayName'] as string;
    this.primaryColor = (input['primaryColor'] || input['primary']) as string;
    this.secondaryColor = (input['secondaryColor'] || input['secondary']) as string;
    this.gradientColor1 = input['gradientColor1'] as string;
    this.gradientColor2 = input['gradientColor2'] as string;
    this.gradientColor3 = input['gradientColor3'] as string;
    this.onBackgroundColor = (input['onBackgroundColor'] || input['onBackground']) as string;
    this.onBackgroundIconAsset = new ContentAsset().deserialize(input['onBackgroundIconAsset']);
    this.onBackgroundIconUrl.set(this.onBackgroundIconAsset?.link);
    this.iconAsset = new ContentAsset().deserialize(input['iconAsset']);
    this.iconUrl.set(this.iconAsset?.link);
    this.backgroundImageAsset = new ContentAsset().deserialize(input['backgroundImageAsset']);
    this.backgroundImageUrl.set(this.backgroundImageAsset?.backgroundLink);
    this.products = (input['products'] ?? []) as Product[];

    return this;
  }

  serialize(): JsonObject {
    return pick(this, [
      'id',
      'displayName',
      'primaryColor',
      'secondaryColor',
      'gradientColor1',
      'gradientColor2',
      'gradientColor3',
      'onBackgroundColor',
    ]);
  }

  getColorWithTransparency(property: IndexableColors, opacity: number): string {
    return this[property]
      ? `rgba(${hexToRgb(this[property]).r}, ${hexToRgb(this[property]).g}, ${hexToRgb(this[property]).b}, ${opacity})`
      : (this[property] as string);
  }
}
