import { OauthResponseTypes } from '@ki/shared/constants.service';

export const environment = {
  production: !window?.location?.origin?.includes('localhost'),
  environment: 'prod',
  apiHostUrl: 'https://api.kumanu.com',
  appUrls: {
    insightful: 'https://insightful.kumanu.com',
    purposeful: 'https://purposeful.kumanu.com',
    resourceful: 'https://resourceful.kumanu.com',
  },
  version: 15,
  hostname: window?.location?.hostname,
  oauth: {
    authClientSsoId: '4aalojqq8dskm2knmtfqre8n2i',
    authClientSocialId: '7qogmffln6d2r7sljgbhst5hi3',
    clientId: 'ljpsqtct00pjl25l7ii26ghn2dcsjrjgeubf07ls19qve1dapsn68kemhck3etb2', // TODO: Android, do we need web?
    domain: 'https://auth.kumanu.com',
    redirectSignIn: 'https://api.kumanu.com/auth/token/sso/insightful',
    redirectSignOut: 'https://insightful.kumanu.com/auth/sso/logout',
    responseType: OauthResponseTypes.code, // 'code' or 'token'
  },
  PUBLISHABLE_KEY: 'pk_live_IkPOVcT4FBnj9WEdWB69rF3K00415fP0lg',
  smbGroupCode: 'smb-holding',
};
