import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class ToolkitTool implements Deserializable<any, ToolkitTool> {
  constructor(
    public title?: string,
    public dateUploaded?: string,
    public fileType?: string,
    public fileName?: string,
    public url?: string
  ) {}

  /**
   * Assign ToolkitTool params from JSON
   */
  deserialize(json: JsonObject): ToolkitTool {
    if (json == null) {
      return null;
    }

    this.dateUploaded = json['dateUploaded'] as string;
    this.fileName = json['fileName'] as string;
    this.fileType = json['fileType'] as string;
    this.title = json['title'] as string;
    this.url = json['url'] as string;

    return this;
  }
}
