import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { AppBloc } from '@ki/app.bloc';
import { GlobalElementsService } from '@ki/shared/services/global-elements.service';
import { WINDOW } from '@kservice';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ki-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _appBloc: AppBloc,
    public globalElementsService: GlobalElementsService,
    private _router: Router,
    @Inject(WINDOW) private _window: Window
  ) {}

  navActive = false;
  skipLinkPath: string;

  private _destroy$ = new Subject<void>();

  ngOnInit(): void {
    // stop supported browser timer from timing out and triggering unsupported browser page
    this._window['isSupportedBrowser'] = true;

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        if (!this._router.url.endsWith('#main')) {
          this.skipLinkPath = `${this._router.url}#main`;
        }
      });

    // Ensure route is checked on load, but push check off until redirect code runs
    setTimeout(() => this.globalElementsService.checkSpecificRoute(this._router.url), 0);

    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromEvent({
        event: AnalyticEvent.app_open,
      })
    );

    this._appBloc.loadStoredData();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  navBtnClicked() {
    this.navActive = !this.navActive;
  }

  sidebarClicked() {
    this.navActive = !this.navActive;
  }
}
