import { Deserializable } from './deserializable.model';

type ImageMimeType = 'image/svg+xml' | 'image/png' | 'image/jpg' | 'image/jpeg' | 'image/gif';

interface ContentAssetApiResponse {
  id?: string;
  data?: string;
  link: string;
  name?: string;
  type?: ImageMimeType;
  alt_text?: string;
}

export class ContentAsset implements Deserializable<ContentAssetApiResponse, ContentAsset> {
  constructor(
    public id?: string,
    public data?: string,
    public link?: string,
    public backgroundLink?: string,
    public name?: string,
    public type?: ImageMimeType,
    public alt_text?: string
  ) {}

  deserialize(input: ContentAssetApiResponse): ContentAsset {
    if (input == null) {
      return null;
    }

    this.id = input['id'];
    this.data = input['data'];
    this.link = input['link'];
    this.backgroundLink = this._setBackgroundUrl(input['link']);
    this.name = input['name'];
    this.type = input['type'];
    this.alt_text = input['alt_text'];

    return this;
  }

  private _setBackgroundUrl(link: string): string {
    if (link && !link.includes('url(')) {
      return `url(${link})`;
    }
    return link;
  }
}
