<!-- Custom checkbox with accessibility adapted from https://www.a11ywithlindsey.com/blog/create-custom-keyboard-accesible-checkboxes -->
<label [ngClass]="{ 'checkbox-reversed': reversed }">
  <input
    #checkboxInput
    type="checkbox"
    [checked]="isChecked"
    (click)="checkboxChanged(checkboxInput.checked)"
    [attr.aria-label]="semanticLabel" />
  <span class="checkbox">
    <span class="check"></span>
  </span>
  <span class="label-content">
    <ng-content></ng-content>
  </span>
</label>
