import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { HttpStatusCode, RequestType } from '@ktypes/enums';
import {
  DataStatus,
  Group,
  Insight,
  JsonObject,
  Metric,
  Organization,
  OverTimeMetric,
  Report,
  Status,
  StatusMessage,
  UserResponseMetric,
} from '@ktypes/models';
import { Observable, firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class OGApi extends BaseApi {
  constructor(
    private _sharedDataStore: DataStoreService,
    private _envVariablesService: EnvironmentVariablesService,
    httpClient: HttpClient
  ) {
    super(httpClient, _sharedDataStore, _envVariablesService);
  }

  async getGroupByCode(groupCode: string): Promise<Group>;
  async getGroupByCode(groupCode: string, verifyAvailability: true): Promise<boolean>;
  async getGroupByCode(groupCode: string, verifyAvailability = false): Promise<Group | boolean> {
    const queryParams: { groupCode: string; verifyAvailability?: boolean } = { groupCode };
    if (verifyAvailability) {
      queryParams['verifyAvailability'] = verifyAvailability;
    }
    const url = this.buildUrl('/group', false, { queryParams });
    const request$ = this.performRequest<Group>(RequestType.GET, url).pipe(
      map((response: HttpResponse<Group>): Group | boolean =>
        verifyAvailability ? response.status === HttpStatusCode.NOT_FOUND : new Group().deserialize(response.body)
      ),
      catchError((error: HttpErrorResponse) => {
        console.warn('Failed requesting group: ', error);
        return of(verifyAvailability ? error.status === HttpStatusCode.NOT_FOUND : null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error requesting group: ', error);
      return null;
    });
  }

  getOrganizationsAndGroups(): Promise<DataStatus<Organization[]>> {
    const url = this.buildUrl('/organizations');
    const dataStatus$ = this.performRequest<Organization[]>(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Organization[]>): DataStatus<Organization[]> => {
        const orgData: Organization[] = response?.body?.map?.((org) => new Organization().deserialize(org));
        if (orgData) {
          return new DataStatus<Organization[]>(Status.done, new StatusMessage(HttpStatusCode.OK, 'OK'), orgData);
        }
        return new DataStatus<Organization[]>(
          Status.error,
          new StatusMessage(HttpStatusCode.NOT_FOUND, 'no organizations found'),
          []
        );
      }),
      catchError((error: HttpErrorResponse /*, caught*/): Observable<DataStatus<Organization[]>> => {
        console.warn('Error calling: getOrganizationsAndGroups: ', error);
        return of(new DataStatus<Organization[]>(Status.error, new StatusMessage(error.status, error.message), []));
      })
    );
    return firstValueFrom(dataStatus$).catch((error): null => {
      console.warn('Error calling: getOrganizationsAndGroups: ', error);
      return null;
    });
  }

  getGroupsFromOrganizationId(orgId: string): Promise<Group[]> {
    const url = this.buildUrl(`/organization/${orgId}/groups`);
    const groups$ = this.performRequest<Group[]>(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Group[]>): Group[] => {
        if (response?.ok) {
          return response.body?.map((group) => new Group().deserialize(group));
        }
        return null;
      }),
      catchError((error /*, caught*/) => {
        console.warn('Error calling: getGroupsFromOrganizationId: ', error);
        return of(null);
      })
    );
    return firstValueFrom(groups$).catch((error): null => {
      console.warn('Error calling: getGroupsFromOrganizationId: ', error);
      return null;
    });
  }

  getOrganizationReportData(orgId: string): Observable<DataStatus<Report[]>> {
    const url = this.buildUrl(`/organization/${orgId}/reports`);
    return this.performRequest<Report[]>(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<Report[]>) => {
        const reports = response?.body;
        if (reports) {
          return new DataStatus<Report[]>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            reports?.map?.((report) => new Report().deserialize(report))
          );
        }
        return new DataStatus<Report[]>(
          Status.error,
          new StatusMessage(HttpStatusCode.NOT_FOUND, 'no reports found, or error parsing'),
          []
        );
      }),
      catchError((error: HttpErrorResponse /*, caught*/) => {
        console.warn('Error calling: getOrganizationReportData', error);
        return of(
          new DataStatus<Organization[]>(
            Status.error,
            new StatusMessage(
              error?.status ?? HttpStatusCode.BAD_REQUEST,
              error?.message ?? 'Error: getOrganizationReportData'
            ),
            []
          )
        );
      })
    );
  }

  getMetrics(groupId: string, orgId: string, currentlyEligible = true) {
    const url = this.buildUrl('/metrics', true);
    return this.performRequest<JsonObject[]>(RequestType.POST, url, {
      includeToken: true,
      requestBody: { groupId, orgId, currentlyEligible },
    }).pipe(
      map((response: HttpResponse<JsonObject[]>) => {
        const metrics = response?.body?.map((metric) => new Metric().deserialize(metric));
        return new DataStatus(Status.done, null, metrics);
      }),
      catchError((err): Observable<DataStatus<Metric[]>> => {
        return of(new DataStatus<Metric[]>(Status.error, err, null));
      })
    );
  }

  getMetricsByDate(groupId: string, orgId: string, currentlyEligible = true) {
    const url = this.buildUrl('/metrics-by-day', true);
    return this.performRequest<JsonObject[]>(RequestType.POST, url, {
      includeToken: true,
      requestBody: { groupId, orgId, currentlyEligible },
    }).pipe(
      map((response: HttpResponse<JsonObject[]>) => {
        const metrics = response?.body?.map((overTimeMetrics) => new OverTimeMetric().deserialize(overTimeMetrics));
        return new DataStatus(Status.done, null, metrics);
      }),
      catchError((err): Observable<DataStatus<OverTimeMetric[]>> => {
        return of(new DataStatus<OverTimeMetric[]>(Status.error, err, null));
      })
    );
  }

  getUserResponseMetrics(groupId: string, orgId: string, metricType: string, currentlyEligible = true) {
    const url = this.buildUrl('/user-response-metrics', true);
    return this.performRequest<JsonObject[]>(RequestType.POST, url, {
      includeToken: true,
      requestBody: { groupId, orgId, currentlyEligible, metricType },
    }).pipe(
      map((response: HttpResponse<JsonObject[]>) => {
        if (Array.isArray(response?.body)) {
          const userResponseMetrics = response?.body?.map((metric) => {
            return new UserResponseMetric().deserialize(metric);
          });
          return new DataStatus(Status.done, null, userResponseMetrics);
        } else {
          return new DataStatus(Status.error, null, null);
        }
      }),
      catchError((err): Observable<DataStatus<UserResponseMetric[]>> => {
        return of(new DataStatus<UserResponseMetric[]>(Status.error, err, null));
      })
    );
  }

  getInsights(groupId: string, orgId: string) {
    const urlGroupOrOrg = groupId != null ? `group/${groupId}` : 'organization';
    const urlAllOrgsOrId = orgId === 'all' ? 's' : `/${orgId}`;
    const url = this.buildUrl(`/${urlGroupOrOrg}${groupId == null ? urlAllOrgsOrId : ''}/insights`);
    return this.performRequest<JsonObject[]>(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      map((response: HttpResponse<JsonObject[]>) => {
        const insights = response?.body?.map((insight) => new Insight().deserialize(insight));
        return new DataStatus(Status.done, null, insights);
      }),
      catchError((err: HttpErrorResponse): Observable<DataStatus<Insight[]>> => {
        return of(
          new DataStatus<Insight[]>(Status.error, new StatusMessage(HttpStatusCode.BAD_REQUEST, err.message), null)
        );
      })
    );
  }
}
