import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { EnvironmentVariablesService } from '@kenv';
import { AuthDataService, DataStoreService } from '@kservice';
import { HttpStatusCode, PaymentRequiredStatusMessage, Product, UserType } from '@ktypes/enums';
import { AuthData, DataStatus, JsonObject } from '@ktypes/models';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, filter, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { BaseAuthenticationBloc } from './base-authentication.bloc';
import { BaseUserBloc } from './base-user.bloc';

const REFRESH_TIMEOUT = 30 * 1000; // 30 seconds

export class BaseAuthRefreshInterceptor implements HttpInterceptor {
  constructor(
    protected _authDataService: AuthDataService,
    protected _authenticationBloc: BaseAuthenticationBloc,
    protected _dataStoreService: DataStoreService,
    protected _environmentVariablesService: EnvironmentVariablesService,
    protected _router: Router,
    protected _userBloc: BaseUserBloc
  ) {}

  private _cancelSubscriptions$ = new Subject<void>();
  private _loggingOut = false;
  private _refreshTokenInProgress = false;
  private _refreshCallMade = false;
  private _refreshStarted: number;
  // @ts-ignore purposefully not typed, so it inherits the type from setTimeout properly
  private _refreshTimer;
  private _refreshTokenSubject = new BehaviorSubject<string>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Need to refactor this method to not ever return an Observable<boolean>
  intercept(req: HttpRequest<any>, next: HttpHandler) /* : Observable<HttpEvent<any> | boolean> */ {
    return next.handle(req).pipe(
      /* HttpEventType's that SwitchMap may receive; currently handling generic and HttpResponse
          HttpSentEvent (Sent=0)
          HttpHeaderResponse (ResponseHeader=2)
          HttpResponse<any> (Response=4)
          HttpProgressEvent (UploadProgress=1 / DownloadProgress=3
          HttpUserEvent<any> (User=5)
      */
      switchMap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // allow Responses to flow through, we'll catch errors in `catchError`
          return of(event);
        } else if (this._loggingOut) {
          // don't try to refresh token once trying to log out
          return of(event);
        } else if (this._refreshTokenInProgress) {
          // do not allow more than 1 refresh request
          if (!this._refreshCallMade && req.url?.includes?.('/auth/token') && req.method === 'PUT') {
            this._refreshCallMade = true;
            return of(event);
          }
          // hold all subsequent requests until refreshToken is complete
          return this._holdWhileTokenRefreshes(req, next);
        } else if (req.url?.includes?.('/auth/token') && req.method === 'PUT') {
          // auth/token request likely externally (not 401 error) - set flags and do not
          // allow more than 1 refresh request, even if from outside the interceptor
          this._refreshTokenInProgress = true;
          this._refreshCallMade = true;

          this._setupFailsafe();
          this._listenForExternalTokenChange();
          return of(event);
        }
        return of(event);
      }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Need to refactor this method to not ever return an Observable<boolean>
      catchError((error: HttpErrorResponse, caught) => {
        if (error instanceof HttpErrorResponse) {
          console.warn(`${error.status} error - ${(error.error as JsonObject)?.message};`);
          // Comment the following in for additional logging for debugging error states
          // console.warn(`\n${error.error?.explanation ? `${error.error?.explanation}` : ''};\nError Url: ${error.url};\nInitiator Page: ${this._router.url}`)
          switch (error.status) {
            case HttpStatusCode.UNAUTHORIZED:
              // if a 401 error is caught on the PUT /auth/token refresh attempt, refreshing was not successful, handle error
              // NOTE: the POST /auth/token is used for MFA verification
              if (req.method === 'PUT' && error.url?.includes('/auth/token')) {
                return this._handleRefreshError(error);
              }
              // ignore 401 errors made to login or mfa endpoints
              if (ignore401Unauthorized(error.status, error.url, this._router.url)) {
                return of(false);
              }
              // handle 401 failed request
              return this._handleAuthenticationError(req, next);
            case HttpStatusCode.PAYMENT_REQUIRED:
              return this._handlePaymentRequiredErrors(error);
            case HttpStatusCode.FORBIDDEN:
              this._authenticationBloc.captureAuthErrors(error);
              break;
            case HttpStatusCode.BAD_REQUEST:
            case HttpStatusCode.NOT_FOUND:
            case HttpStatusCode.INTERNAL_SERVER_ERROR:
              // handle failed request
              return throwError(() => error);
            default:
              console.warn('default intercepted error: ', error, caught);
              return throwError(() => error);
          }
        }
        return throwError(() => error);
      })
    );
  }

  protected _handleAuthenticationError(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | boolean | HttpErrorResponse> | boolean {
    if (
      !req.headers.has('Authorization') ||
      this._router.url?.includes?.('/delete?') ||
      this._router.url?.includes?.('/unsubscribe?')
    ) {
      // do not attempt to refresh token if it wasn't passed original or if on delete or unsubscribe route
      return next.handle(req);
    }
    if (this._refreshTokenInProgress) {
      // fail out if the refresh has taken longer than the REFRESH_TIMEOUT
      if (Date.now() - this._refreshStarted > REFRESH_TIMEOUT) {
        // reset in progress flags
        this._resetInProgress();
        return throwError(() => 'Refresh time exceeded during another held call');
      }
      return this._holdWhileTokenRefreshes(req, next);
    } else {
      this._refreshTokenInProgress = true;
      this._setupFailsafe();

      // Set the _refreshTokenSubject to null so that subsequent API calls will wait
      // until the new token has been retrieved
      this._refreshTokenSubject.next(null);

      // Ensure refresh token exists
      if (this._authenticationBloc.isRefreshTokenUnset) {
        return this._logout();
      }

      // Refresh the token, then retry the request that originally failed
      return this._userBloc.refreshTokenAndUser$().pipe(
        switchMap((refreshTokenResponse: DataStatus<AuthData>) =>
          next.handle(this._updateAuthenticationToken(req, refreshTokenResponse?.data))
        ),
        catchError(this._handleRefreshError.bind(this))
      );
    }
  }

  private _listenForExternalTokenChange() {
    const currentToken = this._dataStoreService.authData?.token;
    // skip the current token, take next, then close subscription
    return this._dataStoreService.authData$
      .pipe(
        skipWhile((authData) => currentToken === authData.token),
        take(1),
        takeUntil(this._cancelSubscriptions$)
      )
      .subscribe(() => {
        // We don't need to do anything with authData as an external source is refreshing it;
        // Just reset the In Progress flags and timer so that the token can be refreshed again
        // now that the external refresh is complete
        this._resetInProgress();
      });
  }

  private _handleRefreshError(error: HttpErrorResponse) {
    const badStatusCodes = [HttpStatusCode.UNAUTHORIZED, HttpStatusCode.FORBIDDEN];
    console.warn(error);

    if (this._loggingOut) {
      // don't do anything else, just allow the user to finish logging out
      return of(false);
    }

    // Log the user out if they ARE logged in and get here with a bad status code
    if (this._authenticationBloc.isLoggedIn() && this._shouldLogout(badStatusCodes, error)) {
      this._logout();
      return of(false);
    }

    // Check for Payment Required codes (not on login)
    // TODO: Is this necessary to check again or will it always now be handled in the first catchError?
    if (error.status === HttpStatusCode.PAYMENT_REQUIRED && this._router.url !== '/login') {
      return this._handlePaymentRequiredErrors(error);
    }

    // reset in progress flags
    this._resetInProgress();

    // Log the user out if they ARE NOT logged in and get here with a bad status code
    // Note: isLoggedIn checks for "full users" login status, not existence of a token, hence the potential need to still log out
    if (!this._authenticationBloc.isLoggedIn() && this._shouldLogout(badStatusCodes, error)) {
      this._logout();
      return of(false);
    }

    // Don't throw error if 401 and current time is after the token expiration
    if (error.status === HttpStatusCode.UNAUTHORIZED && this._authenticationBloc.isTokenExpiredLocally()) {
      return of(false);
    }

    // finally, if the code somehow gets here, throw general error that could be handled elsewhere or sent to Sentry/etc.
    return throwError(() => error);
  }

  private _shouldLogout(badStatusCodes: HttpStatusCode[], error: HttpErrorResponse) {
    return (
      badStatusCodes.includes(error.status) &&
      (!this._authenticationBloc.isTokenExpiredLocally() || this._router.url !== '/login') &&
      this._dataStoreService.authData?.user?.type !== UserType.deletion
    );
  }

  private _logout() {
    const isLoggedIn = this._authenticationBloc.isLoggedIn();
    this._loggingOut = true;
    this._authenticationBloc.logout(isLoggedIn);
    if (!isLoggedIn) {
      let errorRoute = '';
      switch (this._environmentVariablesService.product) {
        case Product.purposeful:
          errorRoute = 'welcome/error';
          break;
        case Product.insightful:
        default:
          errorRoute = 'error';
      }
      void this._router.navigate([errorRoute]);
    }
    return of(false);
  }

  private _holdWhileTokenRefreshes(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If refreshTokenInProgress is true, wait until refreshTokenSubject has a non-null value
    // which means the new token is ready, and we can retry the request again
    return this._refreshTokenSubject.pipe(
      filter((result) => result !== null),
      take(1),
      switchMap(() => {
        return next.handle(this._updateAuthenticationToken(req));
      })
    );
  }

  private _handlePaymentRequiredErrors(error: HttpErrorResponse): Observable<boolean | HttpErrorResponse> {
    if (error?.url?.includes('verifyAvailability=true')) {
      // Don't check payment status if just verifying availability
      return throwError(() => error);
    }
    const errorMessage = parseErrorMessageFromError(error);
    this._authenticationBloc.setPaymentRequiredErrorMessage(errorMessage);
    switch (errorMessage) {
      case PaymentRequiredStatusMessage.INACTIVE_GROUP:
      case PaymentRequiredStatusMessage.GROUP_DISABLED:
      case PaymentRequiredStatusMessage.EXPIRED:
      case PaymentRequiredStatusMessage.PAYMENT_REQUIRED:
        void this._router.navigate(['/error/access-expired/invalid_group']);
        break;
      case PaymentRequiredStatusMessage.EXPIRED_USER:
      case PaymentRequiredStatusMessage.PASSED_ACCOUNT_DURATION:
        void this._router.navigate(['/error/access-expired/trial_expired']);
        break;
      case PaymentRequiredStatusMessage.PAYMENT_NEEDED:
        void this._router.navigate(['/error/access-expired/payment_needed']); //TODO: change to reactivate_account
        break;
      case PaymentRequiredStatusMessage.INITIAL_PAYMENT_NEEDED:
        void this._router.navigate(['/error/access-expired/initial_payment_needed']); //TODO: change to reactivate_account
        break;
      default:
        void this._router.navigate(['/error/access-expired/invalid_group']);
        break;
    }
    return of(false);
  }

  private _resetInProgress() {
    // allow subsequent calls to complete
    this._refreshTokenInProgress = false;

    // allow refresh calls to be attempted again
    this._refreshCallMade = false;

    // clear the failsafe timer
    clearTimeout(this._refreshTimer);

    // clear any subscriptions
    this._cancelSubscriptions$.next();
  }

  private _setupFailsafe() {
    // store the current timestamp, so we can time out if a refresh never completes,
    // and start a timer to auto-fail if it goes on too long
    this._refreshStarted = Date.now();
    this._refreshTimer = setTimeout(() => {
      if (this._refreshTokenInProgress) {
        // Just logout in this case, cannot return error from without timeout method
        return this._logout();
      }
      return null;
    }, REFRESH_TIMEOUT);
  }

  protected _updateAuthenticationToken(request: HttpRequest<any>, refreshTokenResponse?: AuthData): HttpRequest<any> {
    const token = refreshTokenResponse?.token ?? this._dataStoreService.authData.token;
    if (refreshTokenResponse) {
      this._authDataService.updateToken(refreshTokenResponse);
      this._refreshTokenSubject.next(token);
    }

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!token) {
      return request;
    }

    // reset in progress flags
    this._resetInProgress();

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: token,
      },
    });
  }
}

function ignore401Unauthorized(responseStatus: HttpStatusCode, errorUrl: string, routerUrl: string) {
  return (
    responseStatus === HttpStatusCode.UNAUTHORIZED &&
    [errorUrl, routerUrl].some((url) => url?.includes?.('login') || url?.includes?.('mfa'))
  );
}

function parseErrorMessageFromError(error: HttpErrorResponse): string {
  if (!error) {
    return '';
  }
  if (!error.error) {
    return error.message || '';
  }
  return ((error.error as JsonObject).message as string) || ((error.error as JsonObject).explanation as string) || '';
}
