import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SubscriptionBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { OnboardingBloc } from '@ki/onboarding/onboarding.bloc';
import { UserBloc } from '@ki/user/user.bloc';
import { DataStoreService } from '@kservice';
import { Role } from '@ktypes/enums';
import { Status } from '@ktypes/models';
import { combineLatest, of, switchMap } from 'rxjs';
import { filter, map, skipWhile } from 'rxjs/operators';

export function checkAdminStatusGuard(): CanActivateFn {
  return () => {
    const dataStoreService = inject(DataStoreService);
    const environmentVariablesService = inject(EnvironmentVariablesService);
    const onboardingBloc = inject(OnboardingBloc);
    const router = inject(Router);
    const subscriptionBloc = inject(SubscriptionBloc);
    const userBloc = inject(UserBloc);

    if (!dataStoreService.user.isLoggedIn) {
      return true;
    }
    return combineLatest([userBloc.fetchingUser$, dataStoreService.user$]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      switchMap(([_, user]) => {
        const userRolesKeys = user?.roles?.map((userRole) => userRole.key);
        const hasInsightfulAdminAccess = userRolesKeys.includes(Role.INSIGHTFUL_ADMIN);
        const isSMBHoldingGroup = user?.group?.groupCode === environmentVariablesService.config.smbGroupCode;
        // if already an Insightful Admin (and not still in the holding group),
        // has been confirmed regardless of subscription status
        if (hasInsightfulAdminAccess && !isSMBHoldingGroup) {
          return of(true);
        }
        // if user doesn't have a subscription plan yet, email is not confirmed, but
        // still has the saved group info, they should still be able to check out
        if (
          isSMBHoldingGroup &&
          user?.subscriptionPlan == null &&
          user?.email == null &&
          user?.unconfirmedEmail != null &&
          onboardingBloc.readGroupInformation() != null
        ) {
          return of(router.createUrlTree(['/onboarding/verify-email']));
        }
        // if user doesn't have a subscription plan yet, email is confirmed, but
        // still has the saved group info, they should still be able to check out
        // TODO: This will not currently work from login if the user gets logged out, only if a user closes the
        //  tab and comes back later, as login returns a 402 and does not provide the user data
        if (
          isSMBHoldingGroup &&
          user?.subscriptionPlan == null &&
          user?.email != null &&
          user?.unconfirmedEmail == null &&
          onboardingBloc.readGroupInformation() != null
        ) {
          return of(router.createUrlTree(['/onboarding/plan']));
        }
        // if user has a subscription plan, they've done the Stripe checkout, check for confirmation
        if ((user?.group?.subscriptionPlans ?? []).length > 0) {
          if (
            subscriptionBloc.subscriptionStatus == null ||
            subscriptionBloc.subscriptionStatus?.status !== Status.done
          ) {
            subscriptionBloc.getSubscriptionStatus();
          }
          return subscriptionBloc.subscriptionStatus$.pipe(
            map((subStatus) => {
              if (subStatus != null && subStatus?.status !== Status.starting) {
                if (
                  subStatus.data?.access &&
                  !hasInsightfulAdminAccess &&
                  isSMBHoldingGroup &&
                  onboardingBloc.groupInfo() != null
                ) {
                  // if group hasn't confirmed, and still has groupInfo, redirect to confirmation
                  return router.createUrlTree(['/onboarding/confirmation']);
                }
                if (isSMBHoldingGroup && !userRolesKeys.includes(Role.KUMANU_INSIGHTFUL_ADMIN)) {
                  // show error for SMB Holding Group, Insightful Users should not see it
                  return router.createUrlTree(['/error/denied']);
                }
                // allow navigation
                return true;
              }
              // use null to wait for subscriptionStatus to complete
              return null;
            }),
            skipWhile((subStatus) => {
              return subStatus == null;
            })
          );
        }
        if (isSMBHoldingGroup && !userRolesKeys.includes(Role.KUMANU_INSIGHTFUL_ADMIN)) {
          // show error for SMB Holding Group, Insightful Users should not see it
          return of(router.createUrlTree(['/error/denied']));
        }
        return of(true);
      })
    );
  };
}
