export enum RemoteFirstVisitCase {
  checkInCount = 'checkInCount',
  collapsedChallengeInfoIndividual = 'collapsedChallengeInfoIndividual',
  collapsedChallengeInfoGroup = 'collapsedChallengeInfoGroup',
  collapsedChallengeInfoDailyGoals = 'collapsedChallengeInfoDailyGoals',
  completedReflection = 'completedReflection',
  dismissedAccountCreationModal = 'dismissedAccountCreationModal',
  discover = 'explore',
  firstActionAdded = 'firstActionAdded',
  firstQuestAdded = 'firstQuestAdded',
  firstTimeAppVisit = 'firstTimeAppVisit',
  lastModalLaunch = 'lastModalLaunch',
  onboarding = 'onboarding',
  progress = 'progress',
  purposefulVisits = 'purposefulVisits',
  resourcefulVisits = 'resourcefulVisits',
  shownFeaturedPromoOn = 'shownFeaturedPromoOn',
  shownCrossPromotionModal = 'shownCrossPromotionModal',
  shownExpandedLiveSupport = 'shownExpandedLiveSupport',
  shownNotificationsModal = 'shownNotificationsModal',
  shownPurposefulFeedbackModal = 'shownPurposefulFeedbackModal',
  shownResourcefulFeedbackModal = 'shownResourcefulFeedbackModal',
  today = 'today',
  webMigrated = 'webMigrated',
}
