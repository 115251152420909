import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SubscriptionBloc } from '@kbloc';
import { UserBloc } from '@ki/user/user.bloc';
import { DataStoreService } from '@kservice';
import { SubscriptionStatusType } from '@ktypes/enums';
import { Status } from '@ktypes/models';
import { combineLatest, of, switchMap } from 'rxjs';
import { filter, map, skipWhile } from 'rxjs/operators';

export function checkSubscriptionStatusGuard(): CanActivateFn {
  return () => {
    const dataStoreService = inject(DataStoreService);
    const router = inject(Router);
    const subscriptionBloc = inject(SubscriptionBloc);
    const userBloc = inject(UserBloc);

    if (!dataStoreService.user.isLoggedIn) {
      return true;
    }
    return combineLatest([userBloc.fetchingUser$, dataStoreService.user$]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      switchMap(([_, user]) => {
        if (user?.subscriptionPlan != null) {
          subscriptionBloc.getSubscriptionStatus();
          return subscriptionBloc.subscriptionStatus$.pipe(
            map((subStatus) => {
              if (subStatus != null && subStatus?.status !== Status.starting) {
                return (
                  (subStatus.data?.status === SubscriptionStatusType.ACTIVE && subStatus.data?.access) ||
                  router.createUrlTree(['/error/access-expired/payment_needed'])
                );
              }
              return null;
            }),
            skipWhile((subStatus) => {
              return subStatus == null;
            })
          );
        } else {
          return of(true);
        }
      })
    );
  };
}
