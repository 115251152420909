import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OGApi } from '@kapi';
import { InsightfulDataStoreService, SelectedFilter } from '@ki/core/data-store.service';
import { OgSelector } from '@ki/shared/components/og-selector/og-selector';
import { Option } from '@ki/shared/models/option';
import { DataStoreService } from '@kservice';
import { EligibilityInclusionOption, FilterTime, UserResponseMetricType } from '@ktypes/enums';
import {
  DataStatus,
  Group,
  Insight,
  Metric,
  Organization,
  OverTimeMetric,
  Report,
  Status,
  UserResponseMetric,
} from '@ktypes/models';
import { isOfType } from '@kutil';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterSelectorsBloc implements OnDestroy {
  private _destroy$ = new Subject<void>();
  private _insightData$ = new Subject<Observable<DataStatus<Insight[]>>>();
  private _reportData$ = new Subject<Observable<DataStatus<Report[]>>>();
  private _engagementData$ = new Subject<Observable<DataStatus<Metric[]>>>();
  private _cumulativeData$ = new Subject<Observable<DataStatus<OverTimeMetric[]>>>();
  private _responseMetricsData$ = new Subject<Observable<DataStatus<UserResponseMetric[]>>>();
  public updatedFromStorage: boolean;

  constructor(
    private _dataStoreService: DataStoreService,
    private _insightfulDataStoreService: InsightfulDataStoreService,
    private _ogApi: OGApi,
    private _router: Router
  ) {
    this._insightData$
      .pipe(
        debounceTime(250), // debounce requests that happen too close together
        switchMap((insightsStatus: Observable<DataStatus<Insight[]>>) => insightsStatus), // unwrap the Observable
        takeUntil(this._destroy$)
      )
      .subscribe((insightsStatus: DataStatus<Insight[]>) =>
        this._insightfulDataStoreService.setInsights(insightsStatus)
      );

    this._reportData$
      .pipe(
        debounceTime(250), // debounce requests that happen too close together
        switchMap((reportsStatus: Observable<DataStatus<Report[]>>) => reportsStatus), // unwrap the Observable
        takeUntil(this._destroy$)
      )
      .subscribe((reportsStatus: DataStatus<Report[]>) => this._insightfulDataStoreService.setReports(reportsStatus));

    this._engagementData$
      .pipe(
        debounceTime(250), // debounce requests that happen too close together
        switchMap((metrics: Observable<DataStatus<Metric[]>>) => metrics), // unwrap the Observable
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        this._insightfulDataStoreService.setEngagement(result.data, result.status, true);
      });

    this._cumulativeData$
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(250), // debounce requests that happen too close together
        switchMap((overTimeMetrics: Observable<DataStatus<OverTimeMetric[]>>) => overTimeMetrics) // unwrap the Observable
      )
      .subscribe((result) => {
        this._insightfulDataStoreService.setOverTimeMetrics(result.data, result.status, true);
      });

    this._responseMetricsData$
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(250), // debounce requests that happen too close together
        switchMap((metrics: Observable<DataStatus<UserResponseMetric[]>>) => metrics) // unwrap the Observable
      )
      .subscribe((result) => {
        this._insightfulDataStoreService.setResponseMetrics(
          result.data,
          result.status,
          result?.data?.map((metric) => {
            return metric.metricType;
          })
        );
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get organizations$(): Observable<Organization[]> {
    return this._insightfulDataStoreService.organizations$;
  }
  get organizationOptions$(): Observable<Option[]> {
    return this._insightfulDataStoreService.organizations$.pipe(
      map((orgs) => this._createOptions(orgs, OgSelector.organization))
    );
  }
  get groups$(): Observable<Group[]> {
    return this._insightfulDataStoreService.groups$;
  }
  get orgGroups$(): Observable<Group[]> {
    const selectedOrgId = this._insightfulDataStoreService.selectedFilters.selectedOrganizationId;
    if (selectedOrgId !== OgSelector.all) {
      return this._insightfulDataStoreService.groupsByOrg$(selectedOrgId);
    }
    return this._insightfulDataStoreService.groups$;
  }
  get groupOptions$(): Observable<Option[]> {
    return this.orgGroups$.pipe(
      map((groups) =>
        this._createOptions(
          groups,
          OgSelector.group,
          this._insightfulDataStoreService.hasAccess(
            this._insightfulDataStoreService.selectedFilters.selectedOrganizationId
          )
        )
      )
    );
  }

  get eligibilitySelectorVisibility$() {
    return combineLatest([this._insightfulDataStoreService.selectedFilters$, this.orgGroups$]).pipe(
      map(([selectedFilters, orgGroups]) => {
        if (selectedFilters.selectedGroupId === 'all') {
          return orgGroups.some((group) => group?.eligibilityVerificationTypes?.length > 0);
        }
        return (
          orgGroups.find(
            (group) => group.id === selectedFilters.selectedGroupId && group.eligibilityVerificationTypes?.length > 0
          ) != null
        );
      }),
      distinctUntilChanged()
    );
  }

  requestAllOrgs(): void {
    this._ogApi.getOrganizationsAndGroups().then((results) => {
      if (results?.data != null) {
        this._insightfulDataStoreService.setOrganizations(
          results.data.map((org) => new Organization().deserialize(org))
        );
      }
    });
  }

  updateEligibilityStatusFilter(eligibilityStatus: EligibilityInclusionOption) {
    const selectedFilter: SelectedFilter = {};
    // default to Currently Eligible if not set
    selectedFilter.selectedEligibilityStatus = eligibilityStatus ?? EligibilityInclusionOption.currently_eligible;
    this._insightfulDataStoreService.setSelectedFilter(selectedFilter);
  }

  updateFilter(id: string, type: OgSelector) {
    const selectedFilter: SelectedFilter = {
      selectedFilterType: type,
    };
    switch (type) {
      case OgSelector.organization: {
        const orgKeys = Object.keys(this._insightfulDataStoreService.organizations || []);
        if (orgKeys.length === 0) {
          // no orgs in dataStore; don't do anything
          return;
        }
        const defaultOrg = orgKeys.length === 1 ? orgKeys[0] : 'all';
        selectedFilter.selectedOrganizationId = id ? id : defaultOrg;
        // on Organization change, reset group selector to 'All' if not being restored from browser storage
        if (!this.updatedFromStorage) {
          if (
            this._insightfulDataStoreService.hasAccess(id) ||
            selectedFilter.selectedOrganizationId === OgSelector.all
          ) {
            selectedFilter.selectedGroupId = 'all';
          } else {
            selectedFilter.selectedGroupId = this._insightfulDataStoreService.getDefaultGroupId();
          }
        }
        // on any change, no longer consider it updated from storage
        this.updatedFromStorage = false;
        break;
      }
      case OgSelector.group:
        selectedFilter.selectedGroupId = id;
        break;
      case OgSelector.all:
        selectedFilter.selectedGroupId = 'all';
        break;
    }
    this._insightfulDataStoreService.setSelectedFilter(selectedFilter);
  }

  updateUserResponseMetric(metric: UserResponseMetricType, filterName: string) {
    const selectedFilter: SelectedFilter = {};
    selectedFilter[filterName] = metric;
    this._insightfulDataStoreService.setSelectedFilter(selectedFilter);
  }

  filtersUpdated() {
    //TODO: Refactor this.  In the mean time, don't replicate this pattern.
    const type = this._insightfulDataStoreService.selectedFilters.selectedFilterType;
    const filter = this._insightfulDataStoreService.selectedFilters.mostRecentlyUpdatedFilter;
    let shouldWeSkipApiCalls = false;
    // orgId should not be "all" if there is only a single organization available,
    // ensure that it is caught before fetching data
    if (
      this._insightfulDataStoreService.selectedFilters.selectedOrganizationId === OgSelector.all &&
      this._insightfulDataStoreService.organizations?.length === 1
    ) {
      this._insightfulDataStoreService.setSelectedFilter({
        ...this._insightfulDataStoreService.selectedFilters,
        selectedOrganizationId: this._insightfulDataStoreService.organizations?.[0]?.id,
      });
    }
    // Skip API calls for some metric widgets sometimes
    if (
      isOfType<SelectedFilter, FilterTime>(filter, 'selectedPopulationProfileMetric') &&
      filter?.selectedPopulationProfileMetric
    ) {
      this._insightfulDataStoreService
        .getOverTimeMetrics$()
        .pipe(take(1))
        .subscribe((overTime) => {
          if (overTime?.data) {
            shouldWeSkipApiCalls = true;
          }
        });
      if (shouldWeSkipApiCalls) {
        return true;
      }
    }
    this._insightfulDataStoreService.ensureFilterSelectionsAlign();
    switch (type) {
      case OgSelector.organization:
        // Org should call user for all Orgs and Groups data aggregated
        if (this._insightfulDataStoreService.selectedFilters.selectedOrganizationId === OgSelector.all) {
          this.requestAllData();
        } else {
          this.requestOrgData(this._insightfulDataStoreService.selectedFilters.selectedOrganizationId);
        }
        break;
      case OgSelector.group:
        // Group should call org for all Groups data aggregated
        if (
          this._insightfulDataStoreService.selectedFilters.selectedGroupId === OgSelector.all &&
          this._insightfulDataStoreService.selectedFilters.selectedOrganizationId === OgSelector.all
        ) {
          this.requestAllData();
        } else if (this._insightfulDataStoreService.selectedFilters.selectedGroupId === OgSelector.all) {
          this.requestOrgData(this._insightfulDataStoreService.selectedFilters.selectedOrganizationId);
        } else {
          this.requestGroupData(this._insightfulDataStoreService.selectedFilters.selectedGroupId);
        }
        break;
    }
  }

  requestAllData() {
    switch (this._router.url) {
      case '/reports':
        return this._getReportsData();
      case '/dashboard':
      default:
        return this._getWidgetData(OgSelector.all, null);
    }
  }

  requestOrgData(id: string) {
    if (id === OgSelector.all) {
      id = this._insightfulDataStoreService.selectedFilters.selectedOrganizationId;
    }
    switch (this._router.url) {
      case '/reports':
        return this._getReportsData();
      case '/dashboard':
      default:
        return this._getWidgetData(OgSelector.organization, id);
    }
  }

  requestGroupData(id: string) {
    switch (this._router.url) {
      case '/reports':
        return this._getReportsData();
      case '/dashboard':
      default:
        return this._getWidgetData(OgSelector.group, id);
    }
  }

  private _getMetricsData(groupId, orgId, currentlyEligible) {
    this._insightfulDataStoreService.setEngagement(null, Status.starting);
    this._engagementData$.next(this._ogApi.getMetrics(groupId, orgId, currentlyEligible));
  }

  private _getCumulativeData(groupId, orgId, currentlyEligible?) {
    this._insightfulDataStoreService.setOverTimeMetrics(null, Status.starting);
    this._cumulativeData$.next(this._ogApi.getMetricsByDate(groupId, orgId, currentlyEligible));
  }

  private _getInsightsData(groupId, orgId) {
    this._insightfulDataStoreService.setInsights(new DataStatus<Insight[]>(Status.starting, null, null));
    this._insightData$.next(this._ogApi.getInsights(groupId, orgId));
  }

  private _getUserResponseMetricsData(groupId, orgId, metricTypes, currentlyEligible?) {
    this._insightfulDataStoreService.setResponseMetrics(null, Status.starting, metricTypes);
    this._responseMetricsData$.next(this._ogApi.getUserResponseMetrics(groupId, orgId, metricTypes, currentlyEligible));
  }

  // Send event in to trigger new state
  private _getWidgetData(eventType: OgSelector, id: string): void {
    // Set Currently Eligible as the default if not set, otherwise, check if it is the option chosen
    const isCurrentlyEligible =
      this._insightfulDataStoreService.selectedFilters.selectedEligibilityStatus == null ||
      this._insightfulDataStoreService.selectedFilters.selectedEligibilityStatus ===
        EligibilityInclusionOption.currently_eligible;
    switch (eventType) {
      case OgSelector.all:
        this._getMetricsData(null, null, isCurrentlyEligible);
        this._getCumulativeData(null, null, isCurrentlyEligible);
        this._getInsightsData(null, 'all');
        this._getUserResponseMetricsData(
          null,
          null,
          [
            UserResponseMetricType.risks,
            UserResponseMetricType.lifeChallenges,
            UserResponseMetricType.priorities,
            UserResponseMetricType.growthAreas,
            UserResponseMetricType.strengths,
            UserResponseMetricType.focuses,
          ],
          isCurrentlyEligible
        );
        break;
      case OgSelector.organization:
        this._getMetricsData(null, id, isCurrentlyEligible);
        this._getCumulativeData(null, id, isCurrentlyEligible);
        this._getInsightsData(null, id);
        this._getUserResponseMetricsData(
          null,
          id,
          [
            UserResponseMetricType.risks,
            UserResponseMetricType.lifeChallenges,
            UserResponseMetricType.priorities,
            UserResponseMetricType.growthAreas,
            UserResponseMetricType.strengths,
            UserResponseMetricType.focuses,
          ],
          isCurrentlyEligible
        );
        break;
      case OgSelector.group:
        this._getMetricsData(id, null, isCurrentlyEligible);
        this._getCumulativeData(id, null, isCurrentlyEligible);
        this._getInsightsData(id, this._insightfulDataStoreService.selectedFilters.selectedOrganizationId);
        this._getUserResponseMetricsData(
          id,
          null,
          [
            UserResponseMetricType.risks,
            UserResponseMetricType.lifeChallenges,
            UserResponseMetricType.priorities,
            UserResponseMetricType.growthAreas,
            UserResponseMetricType.strengths,
            UserResponseMetricType.focuses,
          ],
          isCurrentlyEligible
        );
        break;
    }
  }

  private _getReportsData(): void {
    this._insightfulDataStoreService.setReports(new DataStatus<Report[]>(Status.starting, null, null));
    if (this._insightfulDataStoreService.selectedFilters.selectedOrganizationId === 'all') {
      // All Org reporting is not currently available, exit
      this._reportData$.next(of(new DataStatus<Report[]>(Status.done, null, [])));
      return;
    }
    this._reportData$.next(
      this._ogApi.getOrganizationReportData(this._insightfulDataStoreService.selectedFilters.selectedOrganizationId)
    );
  }

  private _getTimeframeDates(): { startDate: Date; endDate: Date } {
    // Note: we do not support timeframes anymore at the moment, so this is just asking for all times,
    // beginning to now. See history of this file if we need to support filtering timeframes again.
    const startDate = null;
    const endDate = new Date();
    return { startDate, endDate };
  }

  private _createOptions(ogs: any[], type: OgSelector, hasAccess = false): Option[] {
    if (ogs == null) {
      return [];
    }
    // ensure any non-active groups are not shown in the dropdown menus
    const ogsFiltered = ogs.filter((og) => og.active);
    const options: Option[] = ogsFiltered.map(
      (og: Organization | Group): Option => ({
        name: og.id,
        value: og.name,
      })
    );

    if (options?.length > 1 && (type === OgSelector.organization || hasAccess)) {
      options.unshift({
        name: 'all',
        value: `All ${type.substring(0, 1).toUpperCase()}${type.substring(1)}s`,
        // All Organizations / All Groups
      });
    }
    return options;
  }
}
