import { WritableSignal } from '@angular/core';

let _timeoutId: ReturnType<typeof setTimeout>;

export function setExpiringSignal<SignalType>(
  expiringSignal: WritableSignal<SignalType>,
  value: SignalType,
  expirationTime: number,
  expirationValue?: SignalType
) {
  if (_timeoutId) {
    // if there is an existing timeout, clear it
    clearTimeout(_timeoutId);
  }
  expiringSignal.set(value);

  _timeoutId = setTimeout(() => {
    expiringSignal.set(expirationValue ?? null);
  }, expirationTime);
}
