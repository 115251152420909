import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective, provideTranslocoScope } from '@jsverse/transloco';
import { NAV_ITEM_LIST, SharingBloc, TagBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService, productDisplayName } from '@kservice';
import { MapCategory, NavItem, NavItemType, UserFeature, UserRole } from '@ktypes/models';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'kui-screen-site-map',
  standalone: true,
  imports: [AngularSvgIconModule, CommonModule, RouterLink, TranslocoDirective],
  templateUrl: './screen-site-map.component.html',
  styleUrls: ['./screen-site-map.component.scss'],
  providers: [provideTranslocoScope('ui')],
})
export class ScreenSiteMapComponent implements OnInit {
  constructor(
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService,
    @Inject(NAV_ITEM_LIST) private _navItemList: NavItem[],
    private _sharingBloc: SharingBloc,
    private _tagBloc: TagBloc
  ) {}

  protected readonly productDisplayName = productDisplayName;

  mainCategory$: Promise<NavItem[]>;
  adminCategory$: Promise<NavItem[]>;
  quickActions$: Promise<NavItem[]>;
  features$: Promise<NavItem[]>;
  support$: Promise<NavItem[]>;

  private _userFeatures: UserFeature[] = [];
  private _userRoles: UserRole[] = [];

  ngOnInit() {
    this._dataStoreService.user$.pipe(take(1)).subscribe((user) => {
      this._userFeatures = user?.features || [];
      this._userRoles = user?.roles || [];
    });
    this.mainCategory$ = this._filterNavItems(MapCategory.main);
    this.adminCategory$ = this._filterNavItems(MapCategory.admin);
    this.quickActions$ = this._filterNavItems(MapCategory.quickActions);
    this.features$ = this._filterNavItems(MapCategory.feature);
    this.support$ = this._filterNavItems(MapCategory.support);
  }

  private async _filterNavItems(category: MapCategory): Promise<NavItem[]> {
    const product = this._environmentVariablesService.product;
    return (
      await this._processNavItems(
        this._navItemList.filter(
          (navItem) =>
            navItem &&
            (!navItem.displayOrder || navItem.displayOrder?.[product]) &&
            this._validateFeaturesAndRoles(navItem) &&
            (navItem.mapCategory != null && typeof navItem.mapCategory === 'object'
              ? navItem.mapCategory[product]
              : navItem.mapCategory) === category &&
            !navItem.hideFromMap &&
            !navItem.external
        )
      )
    ).filter((navItem) => navItem != null);
  }

  private _validateFeaturesAndRoles(navItem: NavItem) {
    return (
      (!navItem.feature || this._userFeatures.some((userFeature) => userFeature.key === navItem.feature)) &&
      (!navItem.roles ||
        navItem.roles.every((navItemRole) => this._userRoles.map((userRole) => userRole.key).includes(navItemRole))) &&
      (!navItem.notRoles ||
        navItem.notRoles.every((navItemRole) => !this._userRoles.map((userRole) => userRole.key).includes(navItemRole)))
    );
  }

  private async _processNavItems(navItems: NavItem[]): Promise<NavItem[]> {
    // using promises due to need to check observable from TagBloc
    return Promise.all(
      navItems.map(async (navItem) => {
        const checkedNavItem = (await this._checkNavItem(navItem)) ? { ...navItem } : null;
        if (
          checkedNavItem &&
          Array.isArray(checkedNavItem?.children) &&
          checkedNavItem.children?.some((childNavItem) => Array.isArray(childNavItem.tags))
        ) {
          checkedNavItem.children = [
            ...(await this._processNavItems(checkedNavItem.children)).filter((childNavItem) => childNavItem != null),
          ];
        }
        return checkedNavItem || null;
      })
    );
  }

  private async _checkNavItem(navItem: NavItem): Promise<boolean> {
    const tagsMatch: boolean =
      !Array.isArray(navItem?.tags) ||
      (
        await Promise.all(
          navItem.tags.map(async (tag) => {
            return firstValueFrom(this._tagBloc.userHasTagMatch$(tag));
          })
        )
      ).every((tag) => tag);
    const otherConditionsMet: boolean =
      navItem.type !== NavItemType.invites || (await firstValueFrom(this._sharingBloc.invites$))?.length > 0;
    return tagsMatch && otherConditionsMet;
  }
}
