import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { UserEvent } from '@ktypes/enums';
import { JsonObject } from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class UserEventApi extends BaseApi {
  constructor(
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  async postUserEvent(eventKey: UserEvent, meta?: JsonObject) {
    const requestBody: JsonObject = {
      eventKey,
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
    };

    if (meta) {
      requestBody['meta'] = meta;
    }

    const url = this.buildUrl('/user-events', true);
    const request$ = this.performRequest(RequestType.POST, url, {
      includeToken: true,
      requestBody,
    }).pipe(
      catchError((error) => {
        console.warn('Failed posting user event: ', error);
        return of(null);
      })
    );
    await firstValueFrom(request$).catch((error) => {
      console.warn('Error posting user event: ', error);
      return null;
    });
  }
}
