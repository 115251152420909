import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AnalyticsBloc, PageMappingInfo } from '@kanalytics';
import { UserBloc } from '@ki/user/user.bloc';
import { DataStoreService, WINDOW } from '@kservice';
import find from 'lodash/find';
import { BehaviorSubject, Observable, Subject, finalize, withLatestFrom } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

let initialBrowserLoad = true;

@Injectable({
  providedIn: 'root',
})
export class GlobalElementsService {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _dataStoreService: DataStoreService,
    @Inject(DOCUMENT) private _document: Document,
    private _router: Router,
    private _userBloc: UserBloc,
    @Inject(WINDOW) private _window: Window,
    private _ngZone: NgZone
  ) {
    // Check routes on route changes and show/hide elements per route
    this._router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((navEvent: NavigationEnd) => {
        this._routeCheck(navEvent.urlAfterRedirects);
        this._updateFocusPostNav(navEvent.urlAfterRedirects);
      });
    // Check routes on route start and update user if necessary
    this._router.events
      .pipe(
        filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
        withLatestFrom(this._dataStoreService.user$)
      )
      .subscribe(([_, user]) => {
        if (user?.id && user?.isLoggedIn && !user?.hasLoadedData) {
          this._userBloc.getUser(user.id);
        }
      });

    // kick off analytics
    this._analyticsBloc.initialize();
    this._analyticsBloc.userReadyForAnalytics();
  }

  // Note: these arrays support regex matching for partial matching. For example,
  // '/login' will match all routes that contain /login (including
  // /welcome/reset/code, /login/reset/password, etc).
  routesToHideAllChrome = ['/login', '/error', '/auth'];
  routesToHideHeader = [];
  routesToHideNav = [];
  routesOnboarding = ['/onboarding'];
  routesToHideGroupsDropdown = ['/reports'];
  routesToHideEligibleDropdown = ['/reports'];

  private _showChrome = new BehaviorSubject<boolean>(false);
  get showChrome$(): Observable<boolean> {
    return this._showChrome.asObservable();
  }
  private _showHeader = new BehaviorSubject<boolean>(false);
  get showHeader$(): Observable<boolean> {
    return this._showHeader.asObservable();
  }
  private _showNav = new BehaviorSubject<boolean>(false);
  get showNav$(): Observable<boolean> {
    return this._showNav.asObservable();
  }
  private _showOnboarding = new BehaviorSubject<boolean>(false);
  get showOnboarding$(): Observable<boolean> {
    return this._showOnboarding.asObservable();
  }
  private _showGroupsDropdown = new BehaviorSubject<boolean>(false);
  get showGroupsDropdown$(): Observable<boolean> {
    return this._showGroupsDropdown.asObservable();
  }
  private _showEligibleDropdown = new BehaviorSubject<boolean>(false);
  get showEligibleDropdown$(): Observable<boolean> {
    return this._showEligibleDropdown.asObservable();
  }
  private _currentPageInfo$ = new BehaviorSubject<PageMappingInfo>(null);
  get currentPageInfo$(): Observable<PageMappingInfo> {
    return this._currentPageInfo$.asObservable();
  }

  checkSpecificRoute(url) {
    this._routeCheck(url);
  }

  private _routeCheck(url: string) {
    const urlHasMatch = (arr: string[]) => !!find(arr, (obj) => url.match(RegExp(obj)));

    // const routeUrl = url.split(/[?#]/g)[0];
    const showChrome = !urlHasMatch(this.routesToHideAllChrome);
    const showHeader = showChrome && !urlHasMatch(this.routesToHideHeader);
    const showNav = showChrome && !urlHasMatch(this.routesToHideNav);
    const showOnboarding = showChrome && urlHasMatch(this.routesOnboarding);
    const showGroupsDropdown = showChrome && !urlHasMatch(this.routesToHideGroupsDropdown);
    const showEligibleDropDown = showChrome && !urlHasMatch(this.routesToHideEligibleDropdown);
    this._showHeader.next(showHeader);
    this._showNav.next(showNav);
    this._showOnboarding.next(showOnboarding);
    this._showChrome.next(showChrome);
    this._showGroupsDropdown.next(showGroupsDropdown);
    this._showEligibleDropdown.next(showEligibleDropDown);
  }

  private _updateFocusPostNav(url: string) {
    const elementFound$ = new Subject<void>();
    const pageInfo = this._analyticsBloc.getDynamicPageInfoIfMatch(url);

    // Update page info observable on navigating (for announcing page changes and focusing proper element on navigation)
    this._currentPageInfo$.next(pageInfo);

    // Reset scroll position when navigating (helps on mobile when screens get tall)
    this._window.scrollTo(0, 0);

    // using onMicrotaskEmpty to check for focusElement as page finishes rendering; allowing up to 25 passes
    // to allow for child component to complete rendering. Will fall back to default focus if hits limit.
    // Using finalize to prevent possible memory leaks for elementFound$ Subject
    this._ngZone.onMicrotaskEmpty
      .pipe(
        take(25),
        takeUntil(elementFound$),
        finalize(() => {
          if (!elementFound$.closed) {
            elementFound$.complete();
          }
        })
      )
      .subscribe(() => {
        // Find / focus back to top of page; before nav (#browser-nav-focus) on initial load,
        // otherwise the focusElementSelector if defined or h1, otherwise #nav-focus (just inside #main)
        let defaultNavFocus = false;
        let navFocus: HTMLElement = initialBrowserLoad
          ? this._document.getElementById('browser-nav-focus')
          : this._document.querySelector(pageInfo?.meta?.focusElementSelector || 'h1');
        if (!navFocus) {
          navFocus = this._document.getElementById('nav-focus');
          defaultNavFocus = true;
        }
        if (navFocus) {
          // set tabindex -1 so element is focusable
          navFocus.setAttribute('tabindex', '-1');
          navFocus.focus();

          if (!defaultNavFocus && !elementFound$.closed) {
            elementFound$.next();
            elementFound$.complete();
          }
        }
        if (initialBrowserLoad) {
          initialBrowserLoad = false;
        }
      });
  }
}
