import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { TranslatePipe } from '@kf-loc';
import { TooltipVariations } from '@ktypes/props';
import { UtilsModule } from '@kutil';
import { popperVariation, provideTippyConfig, TippyDirective, tooltipVariation } from '@ngneat/helipopper';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AccordionGroupComponent } from './accordion/accordion-group.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AppSwitcherComponent } from './app-switcher/app-switcher.component';
import { ArcChartComponent } from './arc-chart/arc-chart.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { InviteComponent } from './invites/invite/invite.component';
import { InviteFilterPipe } from './invites/modal-invite/invite-filter.pipe';
import { ModalInviteComponent } from './invites/modal-invite/modal-invite.component';
import { LabelFlagComponent } from './label-flag/label-flag.component';
import { LiveSupportDetailComponent } from './live-support-detail/live-support-detail.component';
import { AudioPlayerComponent } from './media-player/components/audio-player/audio-player.component';
import { VideoPlayerComponent } from './media-player/components/video-player/video-player.component';
import { MediaPlayerComponent } from './media-player/media-player.component';
import { ModalEmailVerificationRequiredComponent } from './modal/modal-email-verification-required/modal-email-verification-required.component';
import { ModalInfoComponent } from './modal/modal-info/modal-info.component';
import { ModalComponent } from './modal/modal.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { ShimmerComponent } from './shimmer/shimmer.component';
import { VersionExpiredComponent } from './version-expired/version-expired.component';
import { WordCloudComponent } from './word-cloud/word-cloud.component';

@NgModule({
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    RouterModule,
    TippyDirective,
    TranslatePipe,
    TranslocoModule,
    UtilsModule,
  ],
  declarations: [
    AccordionComponent,
    AccordionGroupComponent,
    AppSwitcherComponent,
    ArcChartComponent,
    AudioPlayerComponent,
    DropdownComponent,
    InviteComponent,
    InviteFilterPipe,
    LabelFlagComponent,
    LiveSupportDetailComponent,
    MediaPlayerComponent,
    ModalComponent,
    ModalEmailVerificationRequiredComponent,
    ModalInfoComponent,
    ModalInviteComponent,
    RichTextComponent,
    ShimmerComponent,
    VersionExpiredComponent,
    VideoPlayerComponent,
    WordCloudComponent,
  ],
  exports: [
    AccordionComponent,
    AccordionGroupComponent,
    AppSwitcherComponent,
    AngularSvgIconModule,
    ArcChartComponent,
    AudioPlayerComponent,
    DropdownComponent,
    InviteComponent,
    InviteFilterPipe,
    LabelFlagComponent,
    LiveSupportDetailComponent,
    MediaPlayerComponent,
    ModalComponent,
    ModalEmailVerificationRequiredComponent,
    ModalInfoComponent,
    ModalInviteComponent,
    RichTextComponent,
    ShimmerComponent,
    VersionExpiredComponent,
    VideoPlayerComponent,
    WordCloudComponent,
  ],
  providers: [
    provideTippyConfig({
      defaultVariation: 'primary',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        primary: TooltipVariations.primary,
        primaryInfo: TooltipVariations.primaryInfo,
        challengeMenu: TooltipVariations.challengeMenu,
      },
      popperOptions: {
        strategy: 'fixed',
      },
    }),
    provideTranslocoScope('ui'),
  ],
})
export class UiModule {}
