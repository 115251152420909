<ng-container *transloco="let t">
  @if (!showError) {
    <h1 class="title">{{ t('Email verification required') }}</h1>
    <p class="description">{{ t('Press Continue to proceed to verify your email address.') }}</p>
    <div class="button-container">
      <a
        [routerLink]="verificationUrl"
        class="button button-pill button-reverse"
        [ngClass]="{ disabled: callInProgress }"
        (click)="continueVerification($event)">
        {{ t('Continue') }}
      </a>
    </div>
  }
  @if (showError) {
    <svg-icon src="/assets/icons/alert-circle.svg" class="alert-icon"></svg-icon>
    <div class="title">{{ t('Something went wrong') }}</div>
    <p class="description">
      {{ t('Your email verification code request was not processed. Please try again.') }}
    </p>
    <p class="description">{{ t('If the problem persists, contact us at') }} support&#64;kumanu.com.</p>
    <div class="button-container">
      <button class="button button-pill button-reverse full-width" (click)="dismissModal()">
        {{ t('Close') }}
      </button>
    </div>
  }
</ng-container>
