import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { SubscriptionStatusType } from '@ktypes/enums';
import { SubscriptionAccount, SubscriptionSession, SubscriptionStatus } from '@ktypes/models';
import { firstValueFrom, of, retry, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionApi extends BaseApi {
  constructor(
    httpClient: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(httpClient, dataStoreService, _environmentVariablesService);
  }

  async getSubscriptionSession(planId: string): Promise<SubscriptionSession> {
    const url = this.buildUrl(`/subscription/session`, true, { subscriptionPlan: planId });
    const request$ = this.performRequest(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      map((response) => {
        if (response?.ok) {
          return new SubscriptionSession().deserialize(response.body);
        }
        return null;
      }),
      catchError((err) => {
        console.warn('Failed getting subscription session', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting subscription session', error);
      return null;
    });
  }

  async getSubscriptionStatus(retryCount = 0): Promise<SubscriptionStatus> {
    const url = this.buildUrl('/subscription/status', true);
    const request$ = this.performRequest<SubscriptionStatus>(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      mergeMap((subStatusResponse) =>
        retryCount === 0 ||
        (subStatusResponse?.body?.access && subStatusResponse?.body?.status === SubscriptionStatusType.ACTIVE)
          ? of(subStatusResponse)
          : throwError(() => 'Error confirming subscription status')
      ),
      retry({ count: 5, delay: 250, resetOnSuccess: true }),
      catchError((error) => {
        console.warn('Exhausted all retry attempts, subscription status not confirmed', error);
        return of(null);
      }),
      map((response) => {
        if (response?.ok) {
          return new SubscriptionStatus().deserialize(response.body);
        }
        return null;
      }),
      catchError((err) => {
        console.warn('Failed getting subscription status', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting subscription status', error);
      return null;
    });
  }

  async getSubscriptionAccount(): Promise<SubscriptionAccount> {
    const url = this.buildUrl('/subscription/account', true);
    const request$ = this.performRequest(RequestType.GET, url, {
      includeToken: true,
    }).pipe(
      map((response) => {
        if (response && response.ok) {
          return new SubscriptionAccount().deserialize(response.body);
        }
        return null;
      }),
      catchError((err) => {
        console.warn('Failed getting subscription account', err);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting subscription account', error);
      return null;
    });
  }
}
