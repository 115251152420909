import { Injectable } from '@angular/core';
import { SubscriptionApi } from '@kapi';
import { HttpStatusCode } from '@ktypes/enums';
import {
  DataStatus,
  Status,
  StatusMessage,
  SubscriptionAccount,
  SubscriptionPlan,
  SubscriptionSession,
  SubscriptionStatus,
} from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionBloc {
  constructor(private _subscriptionApi: SubscriptionApi) {}

  private _subscriptionAccount = new BehaviorSubject<DataStatus<SubscriptionAccount>>(null);
  private _subscriptionSession = new BehaviorSubject<DataStatus<SubscriptionSession>>(null);
  private _subscriptionStatus = new BehaviorSubject<DataStatus<SubscriptionStatus>>(null);

  get subscriptionAccount$(): Observable<DataStatus<SubscriptionAccount>> {
    return this._subscriptionAccount.pipe(
      filter((status) => status && [Status.starting, Status.done].includes(status.status))
    );
  }

  get subscriptionSession$(): Observable<DataStatus<SubscriptionSession>> {
    return this._subscriptionSession.asObservable();
  }

  get subscriptionStatus$(): Observable<DataStatus<SubscriptionStatus>> {
    return this._subscriptionStatus;
  }

  get subscriptionStatus(): DataStatus<SubscriptionStatus> {
    return this._subscriptionStatus.value;
  }

  get subscriptionAccountData$(): Observable<SubscriptionAccount> {
    return this._subscriptionAccount.pipe(
      filter((status) => status && status.status === Status.done),
      map((status) => status.data)
    );
  }

  getSubscriptionAccount(): void {
    this._subscriptionAccount.next(new DataStatus<SubscriptionAccount>(Status.starting, null, null));
    this._subscriptionApi.getSubscriptionAccount().then((subscriptionAccount) => {
      if (subscriptionAccount && subscriptionAccount.url) {
        this._subscriptionAccount.next(
          new DataStatus<SubscriptionAccount>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            subscriptionAccount
          )
        );
      } else {
        this._subscriptionAccount.next(
          new DataStatus<SubscriptionAccount>(
            Status.error,
            new StatusMessage(HttpStatusCode.BAD_REQUEST, 'Error'),
            subscriptionAccount
          )
        );
      }
    });
  }

  getSubscriptionStatus(retryCount = 0): void {
    // TODO: Once token refresh issue is resolved, add this conditional back to limit the number of status calls

    /*    if (
      this._subscriptionStatus.value &&
      (this._subscriptionStatus.value.status === Status.starting || this._subscriptionStatus.value.data != null)
    ) {
      // if already existing value, don't make a new call
      return;
    }*/

    this._subscriptionStatus.next(new DataStatus<SubscriptionStatus>(Status.starting, null, null));
    this._subscriptionApi.getSubscriptionStatus(retryCount).then((subscriptionStatus) => {
      if (subscriptionStatus) {
        this._subscriptionStatus.next(
          new DataStatus<SubscriptionStatus>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            subscriptionStatus
          )
        );
      } else {
        this._subscriptionStatus.next(
          new DataStatus<SubscriptionStatus>(
            Status.error,
            new StatusMessage(HttpStatusCode.BAD_REQUEST, 'Error'),
            subscriptionStatus
          )
        );
      }
    });
  }

  getSubscriptionSession(subscriptionPlans: SubscriptionPlan[]): void {
    this._subscriptionSession.next(new DataStatus<SubscriptionSession>(Status.starting, null, null));
    this._subscriptionApi.getSubscriptionSession(subscriptionPlans?.[0]?.planId).then((session) => {
      this._subscriptionSession.next(
        new DataStatus<SubscriptionSession>(
          session ? Status.done : Status.error,
          session ? new StatusMessage(HttpStatusCode.OK, 'OK') : new StatusMessage(HttpStatusCode.BAD_REQUEST, 'Error'),
          session
        )
      );
    });
  }
}
