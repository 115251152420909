import { Role } from '@ktypes/enums';
import { MapCategory, NavItem, NavItemType, SubscriptionPlan, ToolkitTool } from '@ktypes/models';

export class Constants {
  static dateFormatForLocal = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
  static ssoLoginUrlTemplate = `$authHost/oauth2/authorize?identity_provider=$provider&redirect_uri=$kumanuHost&response_type=$response_type&client_id=$clientId&prompt=select_account&state=$state&scope=email%20openid%20phone%20profile`;
  static ssoLogoutUrlTemplate = '$authHost/logout?client_id=$clientId&logout_uri=$kumanuLogout';
  static ssoLogoutAndReloginUrlTemplate =
    '$authHost/logout?identity_provider=$provider&redirect_uri=$kumanuHost&response_type=$response_type&client_id=$clientId&prompt=select_account&state=$state&scope=email%20openid%20phone%20profile';

  static supportedBrowsers = [
    {
      displayName: 'Edge',
      name: 'MS-Edge-Chromium',
      version: 80,
      os: ['Windows', 'Mac'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/edge.png',
      url: 'https://www.microsoft.com/en-us/edge/',
    },
    {
      displayName: 'Edge',
      name: 'MS-Edge',
      version: 80,
      os: ['Android', 'iOS'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/edge.png',
      url: 'https://www.microsoft.com/en-us/edge/',
    },
    {
      displayName: 'Chrome',
      name: 'Chrome',
      version: 0,
      os: [],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/chrome.png',
      url: 'https://www.google.com/chrome/',
    },
    {
      displayName: 'Firefox',
      name: 'Firefox',
      version: 0,
      os: [],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/firefox.png',
      url: 'https://www.mozilla.org/en-US/firefox/new/',
    },
    {
      displayName: 'Safari',
      name: 'Safari',
      version: 12,
      os: ['Mac', 'iOS'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/safari.png',
      url: 'https://support.apple.com/downloads/safari',
    },
  ];

  static navItemList: NavItem[] = [
    {
      link: '/dashboard',
      icon: '/assets/icons/icon_navigation_dashboard.svg',
      title: 'Dashboard',
      displayOrder: { insightful: { side: 10 } },
      mapCategory: MapCategory.main,
      external: false,
      type: NavItemType.standard,
    },
    {
      link: '/reports',
      icon: '/assets/icons/icon_navigation_reports.svg',
      title: 'Reports',
      displayOrder: { insightful: { side: 20 } },
      mapCategory: MapCategory.main,
      external: false,
      type: NavItemType.standard,
      notRoles: [Role.INSIGHTFUL_ADMIN],
    },
    {
      link: '/admin',
      icon: '/assets/icons/admin-badge.svg',
      title: 'Admin',
      displayOrder: { insightful: { side: 20 } },
      mapCategory: MapCategory.admin,
      external: false,
      type: NavItemType.insightfulAdmin,
      roles: [Role.INSIGHTFUL_ADMIN],
      children: [
        {
          link: '/admin/overview',
          icon: '/assets/icons/light-bulb-outline.svg',
          title: 'Overview',
          displayOrder: { insightful: { side: 30 } },
          mapCategory: MapCategory.admin,
          external: false,
          type: NavItemType.insightfulAdmin,
          roles: [Role.INSIGHTFUL_ADMIN],
          hideFromNav: true,
        },
        {
          link: '/admin/toolkit',
          icon: '/assets/icons/notes.svg',
          title: 'Toolkit',
          displayOrder: { insightful: { side: 32 } },
          mapCategory: MapCategory.admin,
          external: false,
          type: NavItemType.insightfulAdmin,
          roles: [Role.INSIGHTFUL_ADMIN],
          hideFromNav: false,
        },
        {
          link: '/admin/settings',
          icon: '/assets/icons/gear.svg',
          title: 'Settings',
          displayOrder: { insightful: { side: 34 } },
          mapCategory: MapCategory.admin,
          external: false,
          type: NavItemType.insightfulAdmin,
          roles: [Role.INSIGHTFUL_ADMIN],
          hideFromNav: false,
        },
      ],
    },
    {
      link: '/kumanu-admin',
      icon: '/assets/icons/admin-badge.svg',
      title: 'Kumanu Admin',
      displayOrder: { insightful: { side: 40 } },
      mapCategory: MapCategory.main,
      external: false,
      type: NavItemType.elevatedAccess,
      roles: [Role.KUMANU_INSIGHTFUL_ADMIN],
    },
    {
      link: '/site-map',
      icon: '/assets/icons/globe.svg',
      title: 'Site Map',
      displayOrder: { insightful: { side: 50 } },
      mapCategory: MapCategory.main,
      external: false,
      hideFromMap: true,
      type: NavItemType.standard,
    },
  ];

  static subscriptionPlans: { [key: string]: { [key: string]: Partial<SubscriptionPlan> } } = {
    prod: {
      price_1QeNcnCSlQ5x5Hpn57FhycFl: {
        id: '--',
        name: 'PROD Kumanu Business Starter Plan - 25 Licenses ($100 Monthly)',
        planId: 'price_1QeNcnCSlQ5x5Hpn57FhycFl',
        displayName: 'Starter Plan',
        features: ['25 Licenses'],
        price: 100,
        period: 'Monthly',
      },
      price_1QeNe3CSlQ5x5HpnYCi7MGHM: {
        id: '--',
        name: 'PROD Kumanu Business Growth Plan - 50 Licenses ($175 Monthly)',
        planId: 'price_1QeNe3CSlQ5x5HpnYCi7MGHM',
        displayName: 'Growth Plan',
        features: ['50 Licenses'],
        price: 175,
        period: 'Monthly',
      },
      price_1QeNeTCSlQ5x5HpnECt1e7Db: {
        id: '--',
        name: 'PROD Kumanu Business Professional Plan - 100 Licenses ($325 Monthly)',
        planId: 'price_1QeNeTCSlQ5x5HpnECt1e7Db',
        displayName: 'Professional Plan',
        features: ['100 Licenses'],
        price: 325,
        period: 'Monthly',
      },
      price_1QeNfHCSlQ5x5Hpnbydy7uH6: {
        id: '--',
        name: 'PROD Kumanu Business Accelerator Plan - 250 Licenses ($750 Monthly)',
        planId: 'price_1QeNfHCSlQ5x5Hpnbydy7uH6',
        displayName: 'Accelerator Plan',
        features: ['250 Licenses'],
        price: 750,
        period: 'Monthly',
      },
      price_1QeNfmCSlQ5x5HpnZsJV92nK: {
        id: '--',
        name: 'PROD Kumanu Business Business Plan - 500 Licenses ($1375 Monthly)',
        planId: 'price_1QeNfmCSlQ5x5HpnZsJV92nK',
        displayName: 'Business Plan',
        features: ['500 Licenses'],
        price: 1375,
        period: 'Monthly',
      },
    },
    default: {
      price_1QPT6qCSlQ5x5HpnjBl1r5XR: {
        id: 'a80df6f4-7726-4531-ab0b-f70546fe1741',
        name: 'TEST Kumanu Business Starter Plan - 25 Licenses ($50 Monthly)',
        planId: 'price_1QPT6qCSlQ5x5HpnjBl1r5XR',
        displayName: 'Starter Plan',
        features: ['25 Licenses'],
        price: 50,
        period: 'Monthly',
      },
      price_1QPT9CCSlQ5x5Hpnd4S5Ogri: {
        id: 'd9de73d1-2189-4440-b300-d44f1e027028',
        name: 'TEST Kumanu Business Growth Plan - 50 Licenses ($150 Monthly)',
        planId: 'price_1QPT9CCSlQ5x5Hpnd4S5Ogri',
        displayName: 'Growth Plan',
        features: ['50 Licenses'],
        price: 150,
        period: 'Monthly',
      },
      price_1QPT9eCSlQ5x5Hpnvv0J4EeC: {
        id: '939a8315-0aad-4de4-bfa6-4b9a4349d53f',
        name: 'TEST Kumanu Business Professional Plan - 100 Licenses ($300 Monthly)',
        planId: 'price_1QPT9eCSlQ5x5Hpnvv0J4EeC',
        displayName: 'Professional Plan',
        features: ['100 Licenses'],
        price: 300,
        period: 'Monthly',
      },
      price_1QPTAFCSlQ5x5Hpnvf4788Zl: {
        id: '4d849419-d448-4e26-85c2-bc1ade7d2141',
        name: 'TEST Kumanu Business Accelerator Plan - 250 Licenses ($750 Monthly)',
        planId: 'price_1QPTAFCSlQ5x5Hpnvf4788Zl',
        displayName: 'Accelerator Plan',
        features: ['250 Licenses'],
        price: 750,
        period: 'Monthly',
      },
      price_1QPTBSCSlQ5x5HpnQkQbyiUl: {
        id: '5a93dd62-4ad5-459d-8dbf-e179d6e30b60',
        name: 'TEST Kumanu Business Business Plan - 500 Licenses ($1750 Monthly)',
        planId: 'price_1QPTBSCSlQ5x5HpnQkQbyiUl',
        displayName: 'Business Plan',
        features: ['500 Licenses'],
        price: 1750,
        period: 'Monthly',
      },
    },
  };

  static smbEmailInvite = {
    subject: 'Purposeful App Invitation: Boost Energy, Reduce Stress and Build Healthy Habits',
    body: `Greetings!

I'm excited to invite you to Purposeful, a unique and powerful wellbeing app we're now providing free for everyone in our organization.

Purposeful is designed to help you feel your best, be your best, and thrive — in just a few minutes each day. It's full of tools and resources to help you have more energy, reduce stress, build healthy habits, and so much more.

Follow the link below to get started with Purposeful today. It only takes 5 minutes to sign up.

{sign_up_link}

I've started using Purposeful and it's already helping me feel more energized, keep calm under pressure, and stay focused on what matters most. I'm confident you'll find it as valuable as I have.

If you have any questions, please feel free to reach out.

Sincerely,

{nickname}
`,
  };

  static toolkitItems: Partial<ToolkitTool>[] = [
    {
      title: 'Launching Purposeful in Your Organization',
      dateUploaded: '2025-01-07',
      fileType: 'application/pdf',
      fileName: 'Launching Purposeful in Your Organization.pdf',
      url: '/toolkit/Launching Purposeful in Your Organization.pdf',
    },
    {
      title: 'Power of Purpose Editable Flyer',
      dateUploaded: '2025-01-09',
      fileType: 'application/pdf',
      fileName: 'Power of Purpose editable.pdf',
      url: '/toolkit/Power of Purpose editable.pdf',
    },
    {
      title: 'Sample Email - Purposeful Helps with Focus',
      dateUploaded: '2025-01-12',
      fileType: 'application/msword',
      fileName: 'Sample Purposeful Promotional Email  1.docx',
      url: '/toolkit/Sample Purposeful Promotional Email  1.docx',
    },
    {
      title: 'Sample Email - Purposeful Resource Center',
      dateUploaded: '2025-01-13',
      fileType: 'application/msword',
      fileName: 'Sample Purposeful Promotional Email  2.docx',
      url: '/toolkit/Sample Purposeful Promotional Email  2.docx',
    },
    {
      title: 'Sample Email - Purposeful Tip of the Day',
      dateUploaded: '2025-01-15',
      fileType: 'application/msword',
      fileName: 'Sample Purposeful Promotional Email  3.docx',
      url: '/toolkit/Sample Purposeful Promotional Email  3.docx',
    },
  ];
}

export enum OauthResponseTypes {
  code = 'code',
  token = 'token',
}
