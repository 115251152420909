import { Injectable } from '@angular/core';
import { LegalDocumentApi } from '@kapi';
import { DataStatus, LegalDocument, LegalDocumentItem, LegalDocumentType, Status } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface LegalDocumentStorage {
  privacy: DataStatus<LegalDocument>;
  eula: DataStatus<LegalDocument>;
}
@Injectable({
  providedIn: 'root',
})
export class LegalDocumentsBloc {
  constructor(private _legalDocumentApi: LegalDocumentApi) {}

  private _currentLegalDocumentStatus$ = new BehaviorSubject<DataStatus<LegalDocumentItem[]>>(null);
  private _serverSideAcceptLegalDocumentsResult$ = new BehaviorSubject<boolean>(null);
  private _legalDocumentStorage$ = new BehaviorSubject<LegalDocumentStorage>({
    eula: null,
    privacy: null,
  });

  privacyPledgeAccepted = false;

  get currentLegalDocumentStatus(): DataStatus<LegalDocumentItem[]> {
    return this._currentLegalDocumentStatus$.getValue();
  }

  get currentLegalDocumentStatus$(): Observable<DataStatus<LegalDocumentItem[]>> {
    return this._currentLegalDocumentStatus$.asObservable();
  }

  get legalDocumentStorage$(): Observable<LegalDocumentStorage> {
    return this._legalDocumentStorage$.asObservable();
  }

  get legalPrivacyDocument$(): Observable<LegalDocument | undefined> {
    return this._legalDocumentStorage$.pipe(map((legalDocuments) => legalDocuments?.privacy?.data));
  }

  get legalEulaDocument$(): Observable<LegalDocument | undefined> {
    return this._legalDocumentStorage$.pipe(map((legalDocuments) => legalDocuments?.eula?.data));
  }

  get legalDocumentStorage(): LegalDocumentStorage {
    return this._legalDocumentStorage$.getValue();
  }

  get serverSideAcceptLegalDocumentsResult$(): Observable<boolean> {
    return this._serverSideAcceptLegalDocumentsResult$.asObservable();
  }

  get serverSideAcceptLegalDocumentsResult(): boolean {
    return this._serverSideAcceptLegalDocumentsResult$.getValue();
  }

  async fetchLegalDocuments(groupId: string, docType?: LegalDocumentType) {
    if (!groupId) {
      console.warn('No groupId for Legal Document fetch', docType);
      return;
    }
    // If currently fetching or already fetched, don't queue up
    // another set of requests.
    const fetchingOrAlreadyFetched = (document: DataStatus<LegalDocument>) => {
      return document?.status === Status.starting || document?.data != null;
    };

    if (
      fetchingOrAlreadyFetched(this.legalDocumentStorage.privacy) &&
      fetchingOrAlreadyFetched(this.legalDocumentStorage.eula)
    ) {
      return;
    }

    switch (docType) {
      case LegalDocumentType.PRIVACY:
        this._legalDocumentStorage$.next({
          privacy: new DataStatus<LegalDocument>(Status.starting),
          eula: this._legalDocumentStorage$.value.eula,
        });
        this._legalDocumentStorage$.next({
          privacy: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.PRIVACY),
          eula: this._legalDocumentStorage$.value.eula,
        });
        break;
      case LegalDocumentType.EULA:
        this._legalDocumentStorage$.next({
          privacy: this._legalDocumentStorage$.value.privacy,
          eula: new DataStatus<LegalDocument>(Status.starting),
        });
        this._legalDocumentStorage$.next({
          privacy: this._legalDocumentStorage$.value.privacy,
          eula: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.EULA),
        });
        break;
      default:
        this._legalDocumentStorage$.next({
          privacy: new DataStatus<LegalDocument>(Status.starting),
          eula: new DataStatus<LegalDocument>(Status.starting),
        });
        this._legalDocumentStorage$.next({
          privacy: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.PRIVACY),
          eula: await this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.EULA),
        });
        break;
    }
  }

  setLegalDocumentsError(error: DataStatus<LegalDocument>, docType?: LegalDocumentType) {
    switch (docType) {
      case LegalDocumentType.PRIVACY:
        this._legalDocumentStorage$.next({
          privacy: error,
          eula: this._legalDocumentStorage$.value.eula,
        });
        break;
      case LegalDocumentType.EULA:
        this._legalDocumentStorage$.next({
          privacy: this._legalDocumentStorage$.value.privacy,
          eula: error,
        });
        break;
      default:
        this._legalDocumentStorage$.next({
          privacy: error,
          eula: error,
        });
        break;
    }
  }

  getCurrentLegalDocumentStatus(): void {
    this._legalDocumentApi.getCurrentLegalDocumentStatus().then((docStatus) => {
      this._currentLegalDocumentStatus$.next(docStatus);
    });
  }

  clearLegalDocumentStatus(privacyPledgeStatus = false): void {
    // update privacyPledgeAccepted before clearing
    this.privacyPledgeAccepted = privacyPledgeStatus;
    this._currentLegalDocumentStatus$.next(null);
  }

  resetServerSideLegalDocumentsAcceptance() {
    this._serverSideAcceptLegalDocumentsResult$.next(null);
  }

  serverSideAcceptLegalDocuments(type?: LegalDocumentType) {
    const privacyId = this.legalDocumentStorage?.privacy?.data?.id;
    const eulaId = this.legalDocumentStorage?.eula?.data?.id;
    switch (type) {
      case LegalDocumentType.PRIVACY:
        if (!privacyId) {
          console.warn('Missing privacy policy, unable to accept.');
          this._serverSideAcceptLegalDocumentsResult$.next(false);
          return;
        }
        break;
      case LegalDocumentType.EULA:
        if (!eulaId) {
          console.warn('Missing eula, unable to accept.');
          this._serverSideAcceptLegalDocumentsResult$.next(false);
          return;
        }
        break;
      default:
        if (!privacyId || !eulaId) {
          console.warn('Missing privacy policy and/or eula, unable to accept.');
          this._serverSideAcceptLegalDocumentsResult$.next(false);
          return;
        }
        break;
    }

    Promise.all([
      new Promise<boolean>((resolve) => {
        if (!type || type === LegalDocumentType.PRIVACY) {
          this._legalDocumentApi
            .acceptLegalDocument(privacyId)
            .then((result) => resolve(result))
            .catch(() => resolve(false));
        } else {
          resolve(true);
        }
      }),
      new Promise<boolean>((resolve) => {
        if (!type || type === LegalDocumentType.EULA) {
          this._legalDocumentApi
            .acceptLegalDocument(eulaId)
            .then((result) => resolve(result))
            .catch(() => resolve(false));
        } else {
          resolve(true);
        }
      }),
    ]).then(([privacyResult, eulaResult]) => {
      this._serverSideAcceptLegalDocumentsResult$.next(privacyResult && eulaResult);
    });
  }

  clearLegalDocumentStorage(): void {
    this._legalDocumentStorage$.next({
      eula: null,
      privacy: null,
    });
  }

  resetPrivacyPledgeAccepted() {
    this.privacyPledgeAccepted = false;
  }
}
