import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kui-modal-email-verification-required',
  templateUrl: './modal-email-verification-required.component.html',
  styleUrls: ['./modal-email-verification-required.component.scss'],
})
export class ModalEmailVerificationRequiredComponent {
  @Input() email: string;
  @Input() verificationUrl: string;
  @Output() closeClicked = new EventEmitter<boolean>();
  @Output() continueVerificationClicked = new EventEmitter<string>();

  callInProgress = false;
  showError = false;

  dismissModal() {
    this.closeClicked.emit(true);
  }

  continueVerification(event: Event) {
    if (this.callInProgress) {
      event.preventDefault();
    }
    this.callInProgress = true;
    this.continueVerificationClicked.emit(this.email);
  }
}

export const MockModalEmailVerificationRequiredComponent = MockComponent({
  selector: 'kui-modal-email-verification-required',
  inputs: ['email', 'verificationUrl'],
});
