import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { AuthDataService, DataStoreService } from '@kservice';
import { HttpStatusCode, RequestType } from '@ktypes/enums';
import {
  AuthData,
  Credentials,
  DataStatus,
  InsightfulAdminGroupInfo,
  JsonObject,
  Status,
  StatusMessage,
  Theme,
  User,
} from '@ktypes/models';
import { DateTimeUtil } from '@kutil';
import { Observable, firstValueFrom, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class UserApi extends BaseApi {
  constructor(
    private _authDataService: AuthDataService,
    client: HttpClient,
    dataStoreService: DataStoreService,
    environmentVariablesService: EnvironmentVariablesService
  ) {
    super(client, dataStoreService, environmentVariablesService);
  }

  getUser$(userId: string, token?: string): Observable<User> {
    const url = this.buildUrl('', true, { userId, token, queryParams: {} });
    return this.performRequest<User>(RequestType.GET, url, {
      includeToken: true,
      token,
      userId,
    }).pipe(
      take(1), // ensure only a single response is sent
      map((response: HttpResponse<User>): User => new User().deserialize(response?.body)),
      catchError((error) => {
        console.warn('Failed getting user stream: ', error);
        return of(null);
      })
    );
  }

  async getUser(id: string, token?: string): Promise<User> {
    const request$ = this.getUser$(id, token);
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error getting user: ', error);
      return null;
    });
  }

  public async createOnboardingUser(groupCode: string, userId?: string): Promise<DataStatus<AuthData>> {
    const url = this.buildUrl(`/user/${userId ? `${userId}/` : ''}onboarding`);
    const requestBody = {
      deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      groupCode,
    };
    const request$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed creating onboarding user: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<AuthData>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error creating onboarding user: ', error);
      return error;
    });

    if (!response?.ok) {
      return generateError(response);
    }

    return new DataStatus<AuthData>(
      Status.done,
      new StatusMessage(response.status, ''),
      new AuthData().deserialize(response.body)
    );
  }

  public async createGuestUser(
    groupCode: string,
    eligibilityCandidateId?: string,
    token?: string,
    source?: string
  ): Promise<DataStatus<AuthData>> {
    if (!groupCode) {
      const clientSideErrorResponse = new HttpResponse<AuthData>();
      (clientSideErrorResponse as JsonObject)['error'] = {
        message: 'Internal error - missing groupCode or generalToken.',
      };
      return generateError<AuthData>(clientSideErrorResponse);
    }

    const url = this.buildUrl('/user/temp');
    const requestBody = {
      groupCode,
      dateDeviceCreated: DateTimeUtil.formatInLocal(),
      eligibilityCandidateId,
      source,
      token,
    };
    const request$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed creating guest user: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<AuthData>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error creating guest user: ', error);
      return error;
    });

    if (!response || !response.ok) {
      return generateError(response);
    }

    return new DataStatus(
      Status.done,
      new StatusMessage(response.status, ''),
      new AuthData().deserialize(response.body)
    );
  }

  public async signUp(id = '', email: string): Promise<DataStatus<User>> {
    const PASSWORD_RESET_ERROR = 'Please change your password';
    const GENERIC_ERROR = 'There was an error trying to create your account.';
    const credentials = new Credentials().deserialize({ email });
    if (!email) {
      const clientSideErrorResponse = new HttpResponse<User>();
      (clientSideErrorResponse as JsonObject)['error'] = { message: 'Internal error - email is required.' };
      return generateError<User>(clientSideErrorResponse);
    }
    const url = this.buildUrl('/sign-up', true);
    const request$ = this.performRequest<null>(RequestType.POST, url, {
      includeToken: true,
      requestBody: {
        email: credentials.email.toLowerCase(),
      },
    }).pipe(
      map((result: HttpResponse<null>) => {
        if (result?.ok) {
          return new DataStatus<User>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            new User().deserialize({ unconfirmedEmail: email })
          );
        }
        return new DataStatus<User>(
          Status.error,
          new StatusMessage(result.status, result.statusText || GENERIC_ERROR),
          null
        );
      }),
      catchError((error: HttpErrorResponse /*, caught*/) => {
        console.warn('Failed signing up user email: ', error);
        return of(
          new DataStatus<User>(
            Status.error,
            new StatusMessage(
              error.status,
              error.message === 'PasswordResetRequiredException' ? PASSWORD_RESET_ERROR : GENERIC_ERROR
            ),
            null
          )
        );
      })
    );
    return await firstValueFrom(request$).catch((error): null => {
      console.warn('Error signing up user email: ', error);
      return null;
    });
  }

  async resendCode(email: string) {
    const url = this.buildUrl('/resend-code', true);
    const requestBody = { email };

    const request$ = this.performRequest<boolean>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed to resend code to validate email: ', error);
        return of(null);
      })
    );

    const response = await firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error resend code to validate email: ', error);
      return error;
    });

    return new DataStatus(Status.done, response?.ok);
  }

  async register(email: string, confirmationCode: string) {
    const url = this.buildUrl('/register', true);
    const requestBody = {
      confirmationCode,
      dateDeviceCreated: DateTimeUtil.formatInLocal(),
      email: email.toLowerCase(),
    };

    const request$ = this.performRequest<User>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed registering user: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error registering user: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(Status.done, new StatusMessage(response.status, ''), new User().deserialize(response.body));
    }
    return generateError(response);
  }

  async createInsightfulAdminUser(name: string, credentials: Credentials): Promise<DataStatus<AuthData>> {
    const url = this.buildUrl('/admin/user', false);
    const requestBody = {
      name,
      email: credentials.email.toLowerCase(),
      password: credentials.password,
      dateDeviceCreated: DateTimeUtil.formatInLocal(),
    };

    const request$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed creating Insightful admin user: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error creating Insightful admin user: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(
        Status.done,
        new StatusMessage(response.status, ''),
        new AuthData().deserialize(response.body)
      );
    }
    return generateError(response);
  }

  async verifySignupEmail(code: string): Promise<DataStatus<boolean>> {
    const url = this.buildUrl('/email/verify', true);
    const requestBody = { code };

    const request$ = this.performRequest<boolean>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed verifying signup email: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error verifying signup email: ', error);
      return error;
    });
    return new DataStatus(response?.ok ? Status.done : Status.error, !!response?.ok);
  }

  async confirmInsightfulAdminUser(groupInformation: Partial<InsightfulAdminGroupInfo>): Promise<DataStatus<User>> {
    const url = this.buildUrl('/admin/user/{userId}/confirm', true);
    const requestBody = {
      groupName: groupInformation.groupName,
      groupCode: groupInformation.groupKey,
    };

    const request$ = this.performRequest<User>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed confirming Insightful admin user: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error confirming Insightful admin user: ', error);
      return error;
    });

    return new DataStatus(
      response?.ok ? Status.done : Status.error,
      response?.ok ? new User().deserialize(response?.body) : null
    );
  }

  async hasSetPassword(): Promise<DataStatus<boolean>> {
    const url = this.buildUrl('/set-password', true);
    const response$ = this.performRequest<{ passwordSetRequired: boolean }>(RequestType.GET, url, {
      includeToken: true,
    });
    const response = await firstValueFrom(response$).catch((error: HttpErrorResponse) => {
      console.warn('Error determining if user has set password: ', error);
      return error;
    });
    const passwordSetRequired = response?.ok && response?.body?.passwordSetRequired;
    return new DataStatus(
      response?.ok ? Status.done : Status.error,
      new StatusMessage(response?.status, response?.ok ? 'Ok' : 'Bad Request'),
      passwordSetRequired
    );
  }

  async setPassword(credentials: Credentials): Promise<DataStatus<boolean>> {
    const url = this.buildUrl('/set-password', true);
    const requestBody = {
      password: credentials.password,
    };

    const request$ = this.performRequest<boolean>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed setting user password: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error setting user password: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(Status.done, new StatusMessage(response.status, 'Ok'), true);
    }
    return generateError(response, false);
  }

  async updateUserNickname(nickname: string): Promise<DataStatus<User>> {
    const url = this.buildUrl('/nickname', true);
    const requestBody = {
      nickname,
    };
    const request$ = this.performRequest<User>(RequestType.PUT, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed updating user nickname: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<User>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error updating user nickname: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(Status.done, new StatusMessage(response.status, ''), new User().deserialize(response.body));
    }
    return generateError(response);
  }

  async updateUserEmail(email: string, password: string): Promise<DataStatus<string> | JsonObject> {
    const url = this.buildUrl('/email', true);
    const requestBody = {
      email: email.toLowerCase(),
      password,
    };
    const request$ = this.performRequest<JsonObject>(RequestType.PUT, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed updating user email: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error updating user email: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(Status.done, new StatusMessage(response.status, ''), response.body['accessToken']);
    }
    return generateError(response);
  }

  async updateUserTheme(theme: Theme) {
    const url = this.buildUrl('/theme', true);
    const requestBody = {
      themeId: theme.id,
    };
    const request$ = this.performRequest<JsonObject>(RequestType.PUT, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed updating user theme: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<JsonObject>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error updating user theme: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(Status.done, new User().deserialize(response.body));
    }
    return generateError(response);
  }

  async confirmEmail(accessToken: string, code: string): Promise<DataStatus<User>> {
    // for user changing email
    const url = this.buildUrl('/email/confirm', true);
    const requestBody = {
      accessToken,
      code,
    };
    const request$ = this.performRequest<User>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed verifying user email: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<User>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error verifying user email: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(Status.done, new StatusMessage(response.status, ''), new User().deserialize(response.body));
    }
    return generateError(response);
  }

  async resendNewEmailConfirmation(email: string, accessToken: string): Promise<DataStatus<boolean>> {
    const url = this.buildUrl('/email/resend', true);
    const requestBody = {
      email,
      accessToken,
    };
    const request$ = this.performRequest<boolean>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed resending user email verification: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<boolean>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error resending user email verification: ', error);
      return error;
    });

    if (response?.ok) {
      return new DataStatus(Status.done, new StatusMessage(response.status, ''), null);
    }
    return generateError(response);
  }

  public async convertOnboardingToGuestUser(
    candidateId: string,
    groupCode: string,
    sharingToken: string,
    source: string
  ): Promise<DataStatus<AuthData>> {
    const userId = this.dataStoreService?.authData?.user?.id || this.dataStoreService?.authData?.userId;

    if (userId) {
      const url = this.buildUrl(`/user/${userId}/temp`);
      const requestBody = {
        groupCode,
        candidateId,
        sharingToken,
        source,
        deviceCreatedTimestamp: DateTimeUtil.formatInLocal(),
      };

      const request$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
        catchError((error) => {
          console.warn('Failed converting onboarding user to guest user: ', error);
          return of(null);
        })
      );
      const response = await firstValueFrom<HttpResponse<AuthData>>(request$).catch((error: HttpErrorResponse) => {
        console.warn('Error converting onboarding user to guest user: ', error);
        return error;
      });

      if (response?.ok) {
        return new DataStatus<AuthData>(
          Status.done,
          new StatusMessage(response.status, ''),
          new AuthData().deserialize(response.body)
        );
      }
      return generateError(response);
    }
    return null;
  }

  async deleteUser(): Promise<DataStatus<boolean>> {
    const url = this.buildUrl('', true);
    const request$ = this.performRequest<boolean>(RequestType.DELETE, url, { includeToken: true }).pipe(
      catchError((error) => {
        console.warn('Failed deleting user: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<boolean>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error deleting user: ', error);
      return error;
    });

    switch (response?.status) {
      case HttpStatusCode.OK:
        return new DataStatus<boolean>(Status.done, new StatusMessage(HttpStatusCode.OK, 'Success'), true);
      case HttpStatusCode.UNAUTHORIZED:
        return new DataStatus<boolean>(
          Status.error,
          new StatusMessage(HttpStatusCode.UNAUTHORIZED, 'Authentication failed'),
          false
        );
      case HttpStatusCode.NOT_FOUND:
        return new DataStatus<boolean>(
          Status.error,
          new StatusMessage(HttpStatusCode.NOT_FOUND, 'User not found'),
          false
        );
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
      default:
        return new DataStatus<boolean>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'There was an error deleting the user'),
          false
        );
    }
  }

  async registerPulseUser(groupCode: string): Promise<DataStatus<AuthData>> {
    const url = this.buildUrl('/user/pulse-survey-user');
    const requestBody = {
      dateDeviceCreated: DateTimeUtil.formatInLocal(),
      groupCode,
    };
    const request$ = this.performRequest<{ token: AuthData; user: User }>(RequestType.POST, url, {
      requestBody,
    }).pipe(
      map((response: HttpResponse<{ token: AuthData; user: User }>): DataStatus<AuthData> => {
        const authData = new AuthData().deserialize({ ...response?.body?.token, user: response?.body?.user });
        if (authData) {
          this._authDataService.updateToken(authData);
          return new DataStatus<AuthData>(Status.done, new StatusMessage(HttpStatusCode.OK, 'OK'), authData);
        }
        return new DataStatus<AuthData>(Status.error, new StatusMessage(HttpStatusCode.NOT_FOUND, 'Error'), null);
      }),
      catchError(handleObservableError('Failed registering pulse user: '))
    );
    return firstValueFrom(request$).catch((error): null => {
      console.warn('Error registering pulse user: ', error);
      return null;
    });
  }

  async requestDeleteUser(): Promise<DataStatus<boolean>> {
    const url = this.buildUrl('/delete', true);
    const requestBody = {};
    const request$ = this.performRequest<boolean>(RequestType.POST, url, { includeToken: true, requestBody }).pipe(
      catchError((error) => {
        console.warn('Failed requesting delete user: ', error);
        return of(null);
      })
    );
    const response = await firstValueFrom<HttpResponse<boolean>>(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error requesting delete user: ', error);
      return error;
    });

    switch (response?.status) {
      case HttpStatusCode.OK:
        return new DataStatus<boolean>(Status.done, new StatusMessage(HttpStatusCode.OK, 'Success'), true);
      case HttpStatusCode.UNAUTHORIZED:
        return new DataStatus<boolean>(
          Status.error,
          new StatusMessage(HttpStatusCode.UNAUTHORIZED, 'Authentication failed'),
          false
        );
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
      default:
        return new DataStatus<boolean>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'There was an error sending the email'),
          false
        );
    }
  }
}

function handleObservableError(errorMessage: string) {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return of(new DataStatus(Status.error, new StatusMessage(error.status, errorMessage), null));
  };
}

function generateError<T>(response: HttpResponse<T> | HttpErrorResponse, errorData: any = null): DataStatus<T> {
  const defaultMessage = StatusMessage.generic();

  return new DataStatus<T>(
    Status.error,
    new StatusMessage(
      response?.status || defaultMessage.code,
      ((response as HttpErrorResponse)?.['error'] as HttpErrorResponse)?.message || defaultMessage.message
    ),
    errorData
  );
}
