import { computed, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserApi, UserEventApi } from '@kapi';
import { AuthenticationApi, BaseUserBloc } from '@kauth';
import { AuthDataService, BrowserStorage, DataStoreService } from '@kservice';
import { AuthData, Credentials, DataStatus, InsightfulAdminGroupInfo, Status, User } from '@ktypes/models';

@Injectable({
  providedIn: 'root',
})
export class UserBloc extends BaseUserBloc {
  constructor(
    authDataService: AuthDataService,
    authenticationApi: AuthenticationApi,
    private _browserStorage: BrowserStorage,
    public dataStoreService: DataStoreService,
    private _userApi: UserApi,
    userEventApi: UserEventApi
  ) {
    super(authDataService, authenticationApi, dataStoreService, _userApi, userEventApi);
  }

  private _existingUserEmail = signal<string>('');
  existingUserEmail = computed(() => this._existingUserEmail());

  private _insightfulAdminAuthData = signal<DataStatus<AuthData>>(null);
  insightfulAdminAuthData = computed(() => this._insightfulAdminAuthData());

  private _verifySignupEmailStatus = signal<DataStatus<boolean>>(null);
  verifySignupEmailStatus = computed(() => this._verifySignupEmailStatus());
  verifySignupEmailStatus$ = toObservable(this.verifySignupEmailStatus);

  private _insightfulAdminConfirmationStatus = signal<DataStatus<User>>(null);
  insightfulAdminConfirmationStatus = computed(() => this._insightfulAdminConfirmationStatus());
  insightfulAdminConfirmationStatus$ = toObservable(this.insightfulAdminConfirmationStatus);

  createInsightfulAdminUser(name: string, credentials: Credentials) {
    if (credentials.email) {
      this._existingUserEmail.set(credentials.email);
    }

    this._userApi.createInsightfulAdminUser(name, credentials).then((authDataStatus) => {
      if (authDataStatus?.data) {
        this.dataStoreService.setAuthData(authDataStatus.data);
      }
      this._insightfulAdminAuthData.set(authDataStatus);
    });
  }

  verifySignupEmail(code: string) {
    this._userApi.verifySignupEmail(code).then((responseStatus) => {
      this._verifySignupEmailStatus.set(responseStatus);
    });
  }

  confirmInsightfulAdminUser(
    groupInformation: Partial<InsightfulAdminGroupInfo> = this._browserStorage.getObject(
      'groupInfo',
      true
    ) as InsightfulAdminGroupInfo
  ) {
    if (groupInformation) {
      this._userApi.confirmInsightfulAdminUser(groupInformation).then((confirmationStatus) => {
        this._insightfulAdminConfirmationStatus.set(confirmationStatus);
      });
    } else {
      this._insightfulAdminConfirmationStatus.set(new DataStatus(Status.error));
    }
  }
}
