import { Injectable } from '@angular/core';
import { UserType } from '@ktypes/enums';
import { AuthData, User } from '@ktypes/models';
import { BrowserStorage } from './browser-storage.service';
import { DataStoreService } from './data-store.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  constructor(
    private dataStoreService: DataStoreService,
    private browserStorage: BrowserStorage,
    private themeService: ThemeService
  ) {}

  /**
   * Access the authData from the DataStore
   */
  loadToken(): AuthData {
    // TODO: Does this need to trigger an API call to get latest token/user information?
    return this.dataStoreService.authData;
  }

  /**
   * Updates the token and refresh token after they were updated from the API
   */
  updateToken(tokenData: AuthData) {
    this._saveToken(tokenData);
    return this.dataStoreService.authData;
  }

  /**
   * Clears the authData, user, and/or theme from memory and storage/cookie
   */
  clear({ authData = true, user = true, theme = true }): void {
    if (authData) {
      this.dataStoreService.removeAuthData();
    }
    if (user) {
      this.dataStoreService.removeUser();
    }
    if (theme) {
      this.themeService.removeTheme();
    }
  }

  /**
   * Validate against given user type
   */
  validateUserType(type: UserType): boolean {
    return this.dataStoreService.authData?.user?.type === type;
  }

  /**
   * Set userId and other information after receiving it in onboarding link
   */
  setOnboardingUserInfo(user: User, authData: AuthData) {
    this.dataStoreService.setUser({
      ...this.dataStoreService.user,
      ...user,
    });
    this.dataStoreService.setAuthData({ ...this.dataStoreService.authData, ...authData });
  }

  /**
   * Saves the current authData to localStorage
   */
  private _saveToken(tokenData: AuthData) {
    const storedAuthData = new AuthData().deserialize(this.browserStorage.getObject('authData', true));
    this.dataStoreService.setAuthData(storedAuthData != null ? storedAuthData.combine(tokenData) : tokenData);
  }
}
