import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService, SharedConstants } from '@kservice';
import { HttpStatusCode, RequestType } from '@ktypes/enums';
import { JsonObject } from '@ktypes/models';
import { getQueryParamsFromString } from '@kutil';
import { Observable, of, throwError } from 'rxjs';

export { RequestType } from '@ktypes/enums';

export enum TokenType {
  standard = 'standard',
  pulse_survey = 'pulse_survey',
}

export interface RequestOptions {
  excludeContentType?: boolean;
  headers?: HttpHeaders;
  includeToken?: boolean;
  requestBody?: JsonObject;
  token?: string;
  tokenType?: TokenType;
  userId?: string;
}

export interface UrlOptions extends JsonObject {
  queryParams?: JsonObject;
  token?: string;
  userId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BaseApi {
  // Note: BLoC files that reference APIs that extend BaseAPI cannot be imported here due to circular dependencies
  constructor(
    public httpClient: HttpClient,
    public dataStoreService: DataStoreService,
    public environmentVariablesService: EnvironmentVariablesService
  ) {
    if (!this.API_VERSION) {
      // try again if not set prior to construction
      this.API_VERSION = this.environmentVariablesService.config?.version;
    }
  }

  API_VERSION = this.environmentVariablesService.config?.version;

  protected _userId: string;
  protected _token: string;

  buildUrl(endpoint: string, includeUserId = false, urlOptions: UrlOptions = {}) {
    // handle older calls that didn't have urlOptions, just query params;
    // once all calls to this function are converted to urlOptions we should be able to remove this
    const queryParams = Object.keys(urlOptions || {})?.includes('queryParams') ? urlOptions?.queryParams : urlOptions;
    this._setAuthData(urlOptions);
    if (includeUserId && !this._userId) {
      return '';
    }

    let userIdPart = '';
    // handle case(s) where {userId} is in a different spot in the url than the default /user/:userId
    if (includeUserId && endpoint.includes('{userId}')) {
      endpoint = endpoint.replace('{userId}', this._userId);
    } else if (includeUserId) {
      userIdPart = `/user/${this._userId}`;
    }

    // TODO: Replace query string token with Authorization header via HTTP_INTERCEPTORS and an InterceptorService
    let queryParamsString = '';
    if (queryParams != null) {
      queryParamsString = Object.keys(queryParams)
        .filter((key) => queryParams[key] != null)
        .reduce((acc, key) => {
          const entryValues: string[] = Array.isArray(queryParams[key])
            ? (queryParams[key] as string[])
            : ([queryParams[key]] as string[]);
          const newQueryParams: string = entryValues.reduce(
            (values, value) => `${values}&${key}=${encodeURIComponent(value)}`,
            ''
          );
          return `${acc}${newQueryParams}`;
        }, '');
    }
    return `${this.host()}${userIdPart}${endpoint}?version=${this.API_VERSION}${queryParamsString}`;
  }

  performRequest<T>(
    requestType: RequestType = RequestType.GET,
    url: string,
    requestOptions: RequestOptions = {}
  ): Observable<HttpResponse<T>> {
    const { headers, requestBody, includeToken, userId, token } = requestOptions;
    if (!url) {
      return throwError(() => 'Url is empty');
    }
    this._setAuthData({ userId, token });
    let _headers =
      headers != null && headers.has('Accept')
        ? headers.set('Accept', headers.get('Accept'))
        : new HttpHeaders({ Accept: 'application/json' });
    if (!_headers.has('ngsw-bypass')) {
      // bypass service worker for API calls
      _headers = _headers.set('ngsw-bypass', 'true');
    }
    if (!_headers.has('platform')) {
      _headers = _headers.set('platform', SharedConstants.platform);
    }
    if (this.environmentVariablesService.product && !_headers.has('product')) {
      _headers = _headers.set('product', this.environmentVariablesService.product);
    }
    if (requestType === RequestType.GET) {
      _headers = _headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
    }
    if (
      [RequestType.POST, RequestType.PUT, RequestType.PATCH, RequestType.DELETE].indexOf(requestType) > -1 &&
      requestBody &&
      !requestOptions.excludeContentType
    ) {
      _headers = _headers.set('Content-Type', 'application/json');
    }
    if (
      this.dataStoreService.signalStore?.currentLanguage() != null ||
      this.dataStoreService.user?.settings?.language != null
    ) {
      _headers = _headers.set(
        'Accept-Language',
        this.dataStoreService.signalStore.currentLanguage() || this.dataStoreService.user?.settings?.language
      );
    }
    if (includeToken && !_headers.has('Authorization') && typeof this._token === 'string') {
      _headers = _headers.set('Authorization', this._token || '');
    }
    if (headers != null) {
      headers.keys().forEach((hdrKey) => (_headers = _headers.set(hdrKey, headers.get(hdrKey))));
    }
    if (includeToken && !_headers.get('Authorization')) {
      console.warn('No valid token for API call that requested includeToken:', url);
      return of(
        new HttpResponse<T>({
          body: null,
          status: HttpStatusCode.FORBIDDEN,
          statusText: 'No token provided. No call made.',
        })
      );
    }
    switch (requestType.toLowerCase()) {
      case RequestType.GET:
        return (this.httpClient as HttpClient).get<T>(url, { headers: _headers, observe: 'response' });
      case RequestType.POST:
        return (this.httpClient as HttpClient).post<T>(url, requestBody, { headers: _headers, observe: 'response' });
      case RequestType.PUT:
        return (this.httpClient as HttpClient).put<T>(url, requestBody, { headers: _headers, observe: 'response' });
      case RequestType.PATCH:
        return (this.httpClient as HttpClient).patch<T>(url, requestBody, { headers: _headers, observe: 'response' });
      case RequestType.DELETE:
        return (this.httpClient as HttpClient).delete<T>(url, { headers: _headers, observe: 'response' });
    }
    return null;
  }

  host(): string {
    return this.environmentVariablesService.config.apiHostUrl;
  }

  private _setAuthData(authOptions: { userId?: string; token?: string } = {}) {
    let { userId, token } = { ...getQueryParams(), ...authOptions };
    if ((!userId || !token) && this.dataStoreService.authData) {
      // NOTE: Tested subscription vs using synchronous version here,
      // and they were always the same value - 10/11/22 MB
      userId = this.dataStoreService.authData.user?.id;
      token = this.dataStoreService.authData.token;
    }
    this._userId = userId;
    this._token = token;
  }
}

function getQueryParams(): UrlOptions {
  // get Query Params manually, so it is synchronous and immediately available
  const queryParamsString = location?.search; // specifically not using an injected this.window here
  if (queryParamsString) {
    const queryParams = getQueryParamsFromString(queryParamsString);
    const { token, userId, pulseSurveyId } = queryParams as { token: string; userId: string; pulseSurveyId: string };
    const externalPulseSurvey = !!(token && userId && pulseSurveyId);

    if (externalPulseSurvey) {
      return { userId, token };
    }
  }
  return {};
}
