import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class SubscriptionPlan implements Deserializable<JsonObject, SubscriptionPlan> {
  constructor(
    public id?: string,
    public planId?: string,
    public name?: string,
    public displayName?: string,
    public features?: string[],
    public price?: number,
    public period?: 'Monthly' | 'Annual'
  ) {}

  deserialize(input: JsonObject): SubscriptionPlan {
    if (input == null) {
      return null;
    }

    this.id = input['id'] as string;
    this.planId = input['planId'] as string;
    this.name = input['name'] as string;
    this.displayName = input['displayName'] as string;
    this.features = input['features'] as string[];
    this.price = input['price'] as number;
    this.period = input['period'] as 'Monthly' | 'Annual';

    return this;
  }
}
